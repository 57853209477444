//@ts-check
import { action, makeAutoObservable, reaction, runInAction } from 'mobx';

class UIStore {
  updateMessage = '';
  PCSPluginVersion = '';

  hasSentLog = false;

  availableServices = [];

  allServersConnectionStatus = {
    prod: false,
    stage: false,
    dev: false
  };

  serverAuthStatus = {
    prod: false,
    stage: false,
    dev: false
  };

  message = 'uiStore injected from stores wrapper';

  constructor(rootStore) {
    makeAutoObservable(this, { setServerAuthStatus: action });
    this.rootStore = rootStore;

    const t0 = performance.now();

    this.services = this.rootStore.app.service('services');

    this.disposeIsSignedInReaction = reaction(
      () => rootStore.authStore.isSignedIn,
      isSignedIn => {
        if (isSignedIn) {
          console.log('trigger isSignedIn');
          const t1 = performance.now();
          console.log(`Took ${t1 - t0}ms to login`);
          if (this.rootStore.viewStore.enableNotifications) {
            console.log('trigger find');
            rootStore.pushNotificationStore.find({
              query: { $sort: { id: -1 } }
            });
            rootStore.pushTriggerSendStore.find({
              query: { $sort: { id: -1 } }
            });
            rootStore.userSubscriptionStore.find({
              query: { $sort: { id: -1 } }
            });
          }

          this.disposeCurrentUserReaction = reaction(
            () => rootStore.authStore.currentUser?.email,
            currentUserEmail => {
              if (currentUserEmail) {
                if (currentUserEmail !== 'zfrye@waltersandwolf.com') {
                  if (this.hasSentLog) {
                    return;
                  }
                  console.log('trigger currentUserEmail', currentUserEmail);
                  const user = { ...this.rootStore.authStore.currentUser };
                  console.log('user', user);
                  this.rootStore.authStore.logout();
                  this.rootStore.logStore.create({
                    message: 'Test',
                    appName: '@compass/dev-tools',
                    level: 'error',
                    payload: {
                      user: user,
                      message: 'Unauthorized access attempt'
                    }
                  });
                  this.setHasSentLog(true);
                  return this.rootStore.notificationStore.addNotification({
                    message: 'You are not authorized to use this application',
                    options: { variant: 'error' }
                  });
                }
              }
            }
          );
        }
      }
    );

    if (rootStore.mode === 'prod') {
      rootStore.app.on('login', auth => {
        const { appInsightsStore } = rootStore;
        appInsightsStore
          .create({
            appName: rootStore.appName,
            event: 'authentication',
            eventContext: 'login',
            username: auth?.user?.email,
            version: rootStore.appVersion
          })
          .catch(err => {
            console.log('App-Insight: Login Create Error', err);
          });
        console.log('App-Insight: User login', auth.user);
      });

      rootStore.app.on('logout', auth => {
        const { appInsightsStore } = rootStore;
        appInsightsStore
          .create({
            appName: rootStore.appName,
            event: 'authentication',
            eventContext: 'logout',
            username: auth?.user?.email,
            version: rootStore.appVersion
          })
          .catch(err => {
            console.log('App-Insight: Logout Create Error', err);
          });
        console.log('App-Insight: User logout', auth.user);
      });

      this.disposeSetTrackingPage = reaction(
        () => this.rootStore.routerStore?.currentPath,
        currentPath => {
          if (currentPath) {
            if (currentPath.length <= 200) {
              const { appInsightsStore, authStore } = rootStore;
              appInsightsStore
                .create({
                  appName: rootStore.appName,
                  event: 'navigation',
                  eventContext: currentPath,
                  username: authStore?.currentUser?.email || 'unknown',
                  version: rootStore.appVersion
                })
                .catch(err => {
                  console.log('App-Insight: Logout Create Error', err);
                });
            }
          }
        },
        { fireImmediately: true }
      );

      this.checkPushNotificationPermission();
    }
  }

  dispose = () => {
    this.disposeCurrentUserReaction && this.disposeCurrentUserReaction();
    this.disposeIsSignedInReaction && this.disposeIsSignedInReaction();
    this.disposeSetTrackingPage && this.disposeSetTrackingPage();
    this.rootStore.app && this.rootStore.app.removeListener('login');
    this.rootStore.app && this.rootStore.app.removeListener('logout');
    this.rootStore.app &&
      this.rootStore.app.io &&
      this.rootStore.app.io.removeListener('connect');
    this.rootStore.app &&
      this.rootStore.app.io &&
      this.rootStore.app.io.removeListener('disconnect');
  };

  setHasSentLog = value => {
    this.hasSentLog = value;
  };

  checkPushNotificationPermission = async () => {
    if (this.rootStore.mode !== 'production') return;
    if (!window.Notification) {
      return console.error('Not supported');
    }
    const notificationPermission = Notification.permission;
    console.log('notificationPermission', notificationPermission);
    if (notificationPermission === 'granted') {
      console.log('Permission Granted. Checking for stored deviceConnection');
      const { pushDeviceConnectionStore, authStore } = this.rootStore;
      const foundConnection = await pushDeviceConnectionStore.find({
        query: { deviceId: authStore.deviceId }
      });

      console.log('foundConnection', foundConnection);
      if (foundConnection?.data?.length > 0) {
        console.log(
          'Found a matching deviceConnection, ready to get push notifications'
        );
      } else {
        console.log(
          'Couldnt find a matching deviceConnection, need to recreate'
        );
        this.rootStore.viewStore.subscribePushNotifications().catch(err => {
          console.log('Could not subscribePushNotifications', err);
        });
      }
    }
    if (notificationPermission === 'default') {
      const newPermission =
        this.rootStore.webApiStore.requestNotificationPermission();
      if (
        newPermission !== 'denied' &&
        newPermission !== notificationPermission
      ) {
        return this.checkPushNotificationPermission;
      }
    }
  };

  setServerAuthStatus = (server, value) => {
    this.serverAuthStatus[server] = value;
  };

  getAvailableServices = async () => {
    try {
      const { services } = await this.services.find();
      runInAction(() => {
        this.availableServices = services;
      });
    } catch (err) {
      console.log('Error getAvailableServices:', err);
    }

    return this.availableServices;
  };

  setUpdateMessage = message => {
    this.updateMessage = message;
  };

  setAllServersConnectionStatus = (server, value) => {
    this.allServersConnectionStatus[server] = value;
  };
  get inBeta() {
    return this.rootStore.appVersion.includes('beta');
  }

  get pushDeviceSubscription() {
    const { pushDeviceConnectionStore, authStore } = this.rootStore;
    const currentPushDeviceSubscription = pushDeviceConnectionStore.entities
      .slice()
      .find(i => {
        return i.deviceId === authStore.deviceId;
      });
    console.log('currentPushDeviceSubscription', currentPushDeviceSubscription);
    return currentPushDeviceSubscription;
  }
}

export default UIStore;
