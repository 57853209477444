import { BlobBatchClient, BlobServiceClient } from '@azure/storage-blob';

const cpmUploadSaS = `https://wwpstorage.blob.core.windows.net/wwp-cpm?sp=racwdl&st=2023-12-28T19:55:44Z&se=2025-01-02T03:55:44Z&spr=https&sv=2022-11-02&sr=c&sig=IJUMZtZR0VGSTWHDJE%2BHGhLrlVAUHop51wo5sYqIyZM%3D`;

const revitPluginSaS = `https://wwpstorage.blob.core.windows.net/wwp-revit-plugins?sp=racwdl&st=2023-12-28T19:56:38Z&se=2025-01-02T03:56:38Z&spr=https&sv=2022-11-02&sr=c&sig=h%2FB4Ii3CwaXSByXjqWEHCgK5G2A3sRmsd%2F8ZB9zK%2BQs%3D`;
const stageRevitPluginSaS = `https://wwpstorage.blob.core.windows.net/wwp-revit-plugins-stage?sp=racwdl&st=2023-12-28T19:57:17Z&se=2025-01-02T03:57:17Z&spr=https&sv=2022-11-02&sr=c&sig=gnEczOm75BaSjb%2FrgQoIbLJaOT%2Bkefev6rFvatzt3I8%3D`;
if (import.meta.env.MODE === 'development') console.log('Using stage blob');
export const cpmUploadServiceClient = new BlobServiceClient(cpmUploadSaS);
export const revitPluginServiceClient =
  import.meta.env.MODE === 'development'
    ? new BlobServiceClient(stageRevitPluginSaS)
    : new BlobServiceClient(revitPluginSaS);
export const revitPluginBatchClient =
  import.meta.env.MODE === 'development'
    ? new BlobBatchClient(stageRevitPluginSaS)
    : new BlobBatchClient(revitPluginSaS);
