import BarcodeCameraIcon from '@mui/icons-material/QrCodeScanner';

const mobileCameraRoutes = [
  {
    path: '/barcode-camera',
    layout: '/app',
    name: 'Barcode Camera',
    projectRoute: false,
    icon: BarcodeCameraIcon,
    component: () => import('./BarcodeCamera.Container'),
    requiresPermission: false,
    homeShortcut: true,
    sideBarShortcut: true,
    channels: []
  }
];

export default mobileCameraRoutes;
