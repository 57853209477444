import UpdateHistoryIcon from '@mui/icons-material/History';
import { databaseDate } from '@compass/utils';
import { when } from 'mobx';

const updateHistoryRoutes = [
  {
    path: '/update-history',
    layout: '/app',
    defaultQueryParams: { createdDate: databaseDate() },
    name: 'Update History',
    icon: UpdateHistoryIcon,
    component: () => import('./UpdateHistory.Container'),
    requiresPermission: false,
    homeShortcut: true,
    sideBarShortcut: true,
    channels: ['update-history', 'users'],
    beforeEnter: async ({ rootStore, queryParams }) => {
      const {
        findAllStore: { findAllUpdates }
      } = rootStore;
      findAllUpdates('update-history', {
        createdDate: queryParams?.createdDate
      });
    },
    onEnter: async ({ rootStore }) => {
      const {
        userStore: { find: findUsers }
      } = rootStore;
      when(
        () =>
          rootStore.updateHistoryViewStore?.currentHistoryUserIds?.length > 0,
        () => {
          const currentUserIds =
            rootStore.updateHistoryViewStore?.currentHistoryUserIds;
          findUsers({
            query: {
              id: {
                $in: [...currentUserIds]
              }
            }
          });
        },
        { delay: 500 }
      );
    },

    onParamsChange: async ({ rootStore, params, previousParams }) => {
      if (params?.createdDate !== previousParams?.createdDate) {
        console.log('triggerEnterSideEffects being called');
        console.log('params', params);
        console.log('previousParams', previousParams);
        await rootStore.routerStore.triggerEnterSideEffects();
      }
    }
  }
];

export default updateHistoryRoutes;
