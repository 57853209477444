import UserFeedbackIcon from '@mui/icons-material/QuestionAnswer';
import UserManagementIcon from '@mui/icons-material/AccountCircle';
import PermissionIcon from '@mui/icons-material/Wysiwyg';
import UserPermissionsIcon from '@mui/icons-material/GroupAdd';
import { Key } from '@mui/icons-material';

const userManagementRoutes = [
  {
    path: '/user-feedback',
    layout: '/app',
    name: 'User Feedback',
    icon: UserFeedbackIcon,
    component: () => import('./UserFeedback/UserFeedback.Container'),
    requiresPermission: false,
    homeShortcut: true,
    sideBarShortcut: true,
    channels: ['user-feedback', 'users'],
    beforeEnter: async ({ rootStore }) => {
      const {
        userFeedbackStore: { find }
      } = rootStore;
      find();
    }
  },
  {
    path: '/user-management',
    name: 'User Management',
    icon: UserManagementIcon,
    sideBarShortcut: true,
    homeShortcut: true,
    requiresPermission: false,
    hidden: false,
    component: () => import('./UserManagement/UserManagement.Container'),
    layout: '/app',
    channels: ['users', 'departments'],
    beforeEnter: async ({ rootStore }) => {
      const {
        userStore: { find: findUsers },
        departmentsStore: { find: findDepartments }
      } = rootStore;
      findUsers();
      findDepartments();
    }
  },
  {
    path: '/user-permissions',
    layout: '/app',
    name: 'User Permissions',
    icon: PermissionIcon,
    component: () => import('./UserPermissions/UserPermissions.Container'),
    requiresPermission: false,
    homeShortcut: false,
    sideBarShortcut: false,
    hidden: true,
    channels: ['user-permissions', 'v2/user-permissions', 'users'],
    beforeEnter: async ({ rootStore }) => {
      const {
        userStore: { find: findUsers },
        userPermissionsStore: { find: findUserPermissions }
      } = rootStore;
      findUsers();
      findUserPermissions();
    }
  },
  {
    path: '/user-permissions/:userId',
    name: 'User Permission Management',
    subRoutes: ['UserPermissions'],
    icon: UserPermissionsIcon,
    sideBarShortcut: false,
    homeShortcut: false,
    defaultParams: { userId: '' },
    hidden: true,
    parentRoute: 'User Permissions',
    component: () =>
      import(
        './UserPermissions/UserPermissionManagement/UserPermissionManagement.Container'
      ),
    layout: '/app',
    requiresPermission: false,
    channels: [
      'users/:userId',
      'user-permissions/:userId',
      'v2/user-permissions/:userId'
    ],
    beforeEnter: async ({ rootStore, params }) => {
      const {
        userStore,
        userPermissionsStore: { find: findUserPermissions }
      } = rootStore;
      const { userId } = params;
      console.log('finding user in sql', userId);
      userStore.get(userId);
      findUserPermissions({
        query: { userId: userId, appName: rootStore.appName }
      });
    },
    onParamsChange: async ({ rootStore, params }) => {
      const {
        userStore: { get: getUser },
        userPermissionsStore: { find: findUserPermissions }
      } = rootStore;
      const { userId } = params;
      getUser(userId);
      findUserPermissions({
        query: { userId: userId, appName: rootStore.appName }
      });
    }
  },
  {
    path: '/user-refresh-tokens',
    layout: '/app',
    name: 'User Refresh Tokens',
    icon: Key,
    component: () => import('./RefreshTokens/RefreshTokens.Container'),
    requiresPermission: false,
    homeShortcut: false,
    sideBarShortcut: true,
    channels: ['refresh-token', 'users'],
    beforeEnter: async ({ rootStore }) => {
      const {
        userStore: { find: findUsers },
        findAllStore: { findAllUpdates }
      } = rootStore;
      findAllUpdates('refresh-token');
      findUsers();
    }
  }
];

export default userManagementRoutes;
