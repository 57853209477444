import { databaseDate } from '@compass/utils';
import HomeIcon from '@mui/icons-material/Home';

const dashboardRoutes = [
  {
    path: '/home',
    layout: '/app',
    name: 'Home',
    icon: HomeIcon,
    component: undefined,
    sideBarShortcut: false,
    requiresPermission: false,
    forwardRoute: { route: 'Dashboard' }
  },
  {
    path: '/dashboard',
    layout: '/app',
    name: 'Dashboard',
    icon: HomeIcon,
    component: () => import('./Dashboard.Container'),
    sideBarShortcut: true,
    defaultQueryParams: { createdDate: databaseDate() },
    requiresPermission: false,
    channels: ['channel', 'development/projects'],
    onEnter: async ({ rootStore }) => {
      const {
        developmentProjectsStore,
        channelStore,
        findAllStore: { findAllUpdates }
      } = rootStore;

      developmentProjectsStore.find();
      channelStore.find();
      findAllUpdates('update-history', {
        createdDate: databaseDate()
      });
    }
  }
];

export default dashboardRoutes;
