import barcodeCameraRoutes from './BarcodeCamera/barcodeCameraRoutes';
import dashboardRoutes from './Dashboard/dashboardRoutes';
import databaseAuditRoutes from './DBAudit/databaseAuditRoutes';
import databaseSyncRoutes from './DBSync/databaseSyncRoutes';
import entityViewerRoutes from './EntityViewer/entityViewerRoutes';
import reportViewerRoutes from './ReportViewer/reportViewerRoutes';
import sageViewerRoutes from './SageViewer/sageViewerRoutes';
import settingsRoutes from './Settings/settingsRoutes';
import storeVisualizerRoutes from './StoreVisualizer/storeVisualizerRoutes';
import updateHistoryRoutes from './UpdateHistory/updateHistoryRoutes';
import userManagementRoutes from './UserManagement/userManagementRoutes';

const pagesRoutesList = [
  ...barcodeCameraRoutes,
  ...dashboardRoutes,
  ...databaseAuditRoutes,
  ...databaseSyncRoutes,
  ...entityViewerRoutes,
  ...reportViewerRoutes,
  ...sageViewerRoutes,
  ...settingsRoutes,
  ...storeVisualizerRoutes,
  ...updateHistoryRoutes,
  ...userManagementRoutes
];

export default pagesRoutesList;
