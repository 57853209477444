import PluginUploadIcon from '@mui/icons-material/CloudUpload';

const compassPluginManagerRoutes = [
  {
    path: '/project/revit-plugins/plugin-manager',
    subRoutes: ['ProjectDashboard'],
    layout: '/app',
    name: 'CPM Plugins',
    icon: PluginUploadIcon,
    component: () => import('./CompassPluginManager.Container'),
    requiresPermission: false,
    homeShortcut: true,
    projectRoute: true,
    sideBarShortcut: true,
    channels: [
      'app-version',
      'user-permissions',
      'v2/user-permissions',
      'users'
    ],
    onEnter: async ({ rootStore }) => {
      const {
        revitPluginStore: { listCurrentCloudPlugins },
        userStore: { find: findUsers },
        userPermissionsStore: { find: findUserPermissions },
        appVersionStore: { find: findAppVersions }
      } = rootStore;
      listCurrentCloudPlugins();
      findUsers();
      findUserPermissions({
        query: { appName: '@compass/revit-plugins' }
      });
      findAppVersions({
        query: {
          appName: {
            $like: '@compass/revit-plugins/%'
          }
        }
      });
    }
  }
];

export default compassPluginManagerRoutes;
