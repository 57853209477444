import ChannelViewerIcon from '@mui/icons-material/SettingsInputAntenna';

const channelViewerRoute = [
  {
    path: '/project/:currentProject/channel-viewer',
    layout: '/app',
    name: 'Channel Viewer',
    icon: ChannelViewerIcon,
    component: () => import('./ChannelViewer.Container'),
    requiresPermission: false,
    homeShortcut: false,
    projectRoute: true,
    sideBarShortcut: false,
    channels: ['channel', 'user'],
    beforeEnter: async ({ rootStore }) => {
      const { channelStore, userStore, refreshTokenStore } = rootStore;

      channelStore.find().then(response => {
        if (response?.data?.length > 0) {
          const sqlIds = response.data
            .filter(item => !isNaN(item.userId))
            .map(item => item.userId);
          if (sqlIds.length) {
            userStore.find({ query: { id: { $in: [...sqlIds] } } });
            refreshTokenStore.find({ query: { userId: { $in: [...sqlIds] } } });
          }
        }
      });
    },
    onEnter: async ({ rootStore, params }) => {
      const { appVersionStore } = rootStore;

      if (params?.currentProject)
        appVersionStore.find({
          query: {
            $sort: {
              releaseDate: -1
            },
            appName: `@compass/${params.currentProject}`,
            $limit: 2000
          }
        });
    }
  },
  {
    path: '/project/:currentProject/channel-viewer/:userId',
    layout: '/app',
    name: 'Channel Viewer User',
    icon: ChannelViewerIcon,
    component: () => import('./ChannelViewerUser.Container'),
    requiresPermission: false,
    homeShortcut: false,
    projectRoute: true,
    parentRoute: 'Channel Viewer',
    sideBarShortcut: false,
    channels: ['channel', 'user'],
    beforeEnter: async ({ rootStore, params }) => {
      const { channelStore, userStore, refreshTokenStore } = rootStore;
      channelStore.find();
      if (params?.userId !== 'anonymous') {
        userStore.get(params.userId);
        refreshTokenStore.find({ query: { userId: params.userId } });
      }
    },

    onEnter: async ({ rootStore, params }) => {
      const { appVersionStore } = rootStore;

      if (params?.currentProject)
        appVersionStore.find({
          query: {
            $sort: {
              releaseDate: -1
            },
            appName: `@compass/${params.currentProject}`,
            $limit: 2000
          }
        });
    }
  }
];

export default channelViewerRoute;
