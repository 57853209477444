import AppVersionIcon from '@mui/icons-material/Apps';

const appVersionManagerRoutes = [
  {
    path: '/project/:currentProject/app-version',
    subRoutes: ['ProjectDashboard'],
    layout: '/app',
    name: 'App Versions',
    icon: AppVersionIcon,
    component: () => import('./VersionManager.Container'),
    requiresPermission: false,
    homeShortcut: true,
    projectRoute: true,
    sideBarShortcut: true,
    subscription: { type: 'devops', notificationType: 'push' },
    channels: ['app-version/:currentProject'],
    onEnter: async ({ rootStore, params }) => {
      const {
        appVersionStore: { find: findAppVersions }
      } = rootStore;
      findAppVersions({
        query: {
          $sort: {
            releaseDate: -1
          },
          $limit: 2000,
          appName: '@compass/' + params?.currentProject
        }
      });
    }
  }
];

export default appVersionManagerRoutes;
