import { makeAutoObservable, reaction, runInAction, toJS } from 'mobx';
import js2xml from 'xml-js';
import {
  revitPluginBatchClient,
  revitPluginServiceClient
} from '../utilStores/azureStorage';

import {
  databaseDate,
  databaseTime,
  readableJS,
  renderDate,
  uniqueArray,
  updateVersion,
  uuid
} from '@compass/utils';

async function blobToString(blob) {
  const fileReader = new FileReader();
  return new Promise((resolve, reject) => {
    fileReader.onloadend = ev => {
      resolve(ev.target.result);
    };
    fileReader.onerror = reject;
    fileReader.readAsText(blob);
  });
}

const emptyPlugin = {
  shortName: function () {
    return this.name.replaceAll(' ', '');
  },
  pluginName: function () {
    return `@compass/revit-plugins/${this.shortName()}`;
  },
  version: updateVersion('23.1.1')
};

const getContainerClient = () => {
  return revitPluginServiceClient.getContainerClient('');
};

const newEmptyPlugin = {
  ...emptyPlugin,
  guid: '',
  name: '',
  developers: '',
  releaseNotes: ''
};
const containerClient = getContainerClient();
class RevitPluginStore {
  currentUploadedPluginFiles = [];
  currentFileUpload = '';
  newPluginDialog = newEmptyPlugin;
  existingPluginDialog = emptyPlugin;
  availablePlugins = {};
  mode = 'prod';
  manageUsersFilter = '';
  currentDepartmentSelection = 'D&E';
  updateLevel = '';
  constructor(rootStore) {
    makeAutoObservable(this, {
      rootStore: false,
      userStore: false
    });
    this.rootStore = rootStore;
    this.generateNewPlugin();

    this.disposeBundleSetReaction = reaction(
      () => this.rootStore.routerStore?.queryParams?.dialogName,
      dialogName => {
        if (!dialogName || dialogName === 'Add Plugin') {
          this.resetNewPluginDialog();
        }
      }
    );

    this.disposeUpdatePlugin = reaction(
      () => this.updateDialogPlugin,
      plugin => {
        if (!plugin || !plugin.version) {
          return;
        }
        if (
          !plugin ||
          (plugin &&
            (!this.rootStore.routerStore.queryParams ||
              !this.rootStore.routerStore.queryParams.dialogName ||
              this.rootStore.routerStore.queryParams.dialogName !==
                'Update Plugin'))
        ) {
          console.log('REACTION: resetExistingPluginDialog');
          return this.resetExistingPluginDialog();
        }
        if (plugin.oldVersion && !plugin.version) {
          console.log('REACTION: incrementPluginVersion');
          return this.incrementPluginVersion();
        }
      },
      { fireImmediately: true }
    );

    this.setUpdateLevel('Low');
  }

  dispose = () => {
    this.disposeBundleSetReaction && this.disposeBundleSetReaction();
  };

  resetExistingPluginDialog = () => {
    this.resetCurrentUploadedPlugins();
    this.existingPluginDialog = emptyPlugin;
  };

  resetNewPluginDialog = () => {
    this.newPluginDialog = newEmptyPlugin;
  };

  switchMode = mode => {
    this.mode = mode;
    this.listCurrentCloudPlugins();
  };

  filterList = value => {
    this.manageUsersFilter = value;
  };

  generateNewPlugin = () => {
    this.newPluginDialog.guid = uuid().toUpperCase();

    const month = renderDate(new Date(), { format: 'yy.M' });

    const version = `${month}.1`;
    this.newPluginDialog.version = version;
  };

  generateNewUUID = () => {
    this.newPluginDialog.guid = uuid().toUpperCase();
  };

  addCurrentlyUploadedPluginFiles = file => {
    if (
      !this.currentUploadedPluginFiles.includes(file) &&
      !file.includes('.png')
    )
      runInAction(() => {
        this.currentUploadedPluginFiles.push(file);
      });
  };

  setCurrentFileUpload = file => {
    this.currentFileUpload = file;
  };
  resetCurrentUploadedPlugins = () => {
    runInAction(() => {
      this.currentUploadedPluginFiles = [];
    });
  };

  generateAddinXML = () => {
    const jsonFromXML = {
      declaration: {
        attributes: {
          version: '1.0',
          encoding: 'utf-8',
          standalone: 'no'
        }
      },
      elements: [
        {
          type: 'element',
          name: 'RevitAddIns',
          elements: [
            {
              type: 'element',
              name: 'AddIn',
              attributes: {
                Type: 'Application'
              },
              elements: [
                {
                  type: 'element',
                  name: 'Name',
                  elements: [
                    {
                      type: 'text',
                      text: `${this.newPluginDialog.shortName()}`
                    }
                  ]
                },
                {
                  type: 'element',
                  name: 'Assembly',
                  elements: [
                    {
                      type: 'text',
                      text:
                        'C:\\FDG Lisp\\WWP_PlugIn\\Revit\\' +
                        `${
                          this.newPluginDialog.name
                        }\\${this.newPluginDialog.shortName()}.dll`
                    }
                  ]
                },
                {
                  type: 'element',
                  name: 'AddInId',
                  elements: [
                    {
                      type: 'text',
                      text: this.newPluginDialog.guid
                    }
                  ]
                },
                {
                  type: 'element',
                  name: 'FullClassName',
                  elements: [
                    {
                      type: 'text',
                      text: `${this.newPluginDialog.shortName()}`
                    }
                  ]
                },
                {
                  type: 'element',
                  name: 'VendorId',
                  elements: [
                    {
                      type: 'text',
                      text: '6C31P5FA-CK7E-46C3-A48C-ABHD7K915FZ4'
                    }
                  ]
                },
                {
                  type: 'element',
                  name: 'VendorDescription',
                  elements: [
                    {
                      type: 'text',
                      text: 'Compass, wwprecast.com'
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    };
    const result = js2xml.json2xml(jsonFromXML);

    return result;
  };

  updateAddinXML = () => {
    const jsonFromXML = {
      declaration: {
        attributes: {
          version: '1.0',
          encoding: 'utf-8',
          standalone: 'no'
        }
      },
      elements: [
        {
          type: 'element',
          name: 'RevitAddIns',
          elements: [
            {
              type: 'element',
              name: 'AddIn',
              attributes: {
                Type: 'Application'
              },
              elements: [
                {
                  type: 'element',
                  name: 'Name',
                  elements: [
                    {
                      type: 'text',
                      text: this.updateDialogPlugin.shortName
                    }
                  ]
                },
                {
                  type: 'element',
                  name: 'Assembly',
                  elements: [
                    {
                      type: 'text',
                      text:
                        'C:\\FDG Lisp\\WWP_PlugIn\\Revit\\' +
                        `${this.updateDialogPlugin.name}\\${this.updateDialogPlugin.shortName}.dll`
                    }
                  ]
                },
                {
                  type: 'element',
                  name: 'AddInId',
                  elements: [
                    {
                      type: 'text',
                      text: this.updateDialogPlugin.guid
                    }
                  ]
                },
                {
                  type: 'element',
                  name: 'FullClassName',
                  elements: [
                    {
                      type: 'text',
                      text: this.updateDialogPlugin.shortName
                    }
                  ]
                },
                {
                  type: 'element',
                  name: 'VendorId',
                  elements: [
                    {
                      type: 'text',
                      text: '6C31P5FA-CK7E-46C3-A48C-ABHD7K915FZ4'
                    }
                  ]
                },
                {
                  type: 'element',
                  name: 'VendorDescription',
                  elements: [
                    {
                      type: 'text',
                      text: 'Compass, wwprecast.com'
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    };
    const result = js2xml.json2xml(jsonFromXML);

    return result;
  };

  generateAddinManifest = () => {
    const manifestJson = {
      version: this.newPluginDialog.version,
      developers: this.newPluginDialog.developers
    };
    return manifestJson;
  };

  updateAddinManifest = () => {
    const manifestJson = {
      version: this.updateDialogPlugin.version,
      developers: this.updateDialogPlugin.developers
    };
    return manifestJson;
  };

  createNewPluginFiles = async () => {
    const addinXML = this.generateAddinXML();
    const addinBlob = new Blob([addinXML], { type: 'text/xml' });

    const addinBlobClient = containerClient.getBlockBlobClient(
      `${this.newPluginDialog.name}/${this.newPluginDialog.shortName()}.addin`
    );

    await addinBlobClient.uploadData(addinBlob, {
      // blockSize: 4 * 1024 * 1024, // 4MB block size
      concurrency: 20 // 20 concurrency
    });

    const addinManifest = this.generateAddinManifest();

    const manifestBlob = new Blob([JSON.stringify(addinManifest)], {
      type: 'application/json'
    });

    const manifestBlobClient = containerClient.getBlockBlobClient(
      `${this.newPluginDialog.name}/manifest.json`
    );

    const uploadManifestBlobResponse = await manifestBlobClient.uploadData(
      manifestBlob,
      {
        // blockSize: 4 * 1024 * 1024, // 4MB block size
        concurrency: 20 // 20 concurrency
      }
    );

    if (
      uploadManifestBlobResponse &&
      uploadManifestBlobResponse.clientRequestId
    ) {
      const pluginAppVersion = {
        appName: this.newPluginDialog.pluginName(),
        version: addinManifest.version,
        publishedBy: this.rootStore.authStore.currentUser.email,
        releaseNotes: this.newPluginDialog.releaseNotes,

        releaseDate: databaseDate(),
        releaseTime: databaseTime(),

        required: true
      };
      await this.rootStore.appVersionStore
        .create(pluginAppVersion)
        .then(() => {
          this.listCurrentCloudPlugins(this.newPluginDialog.name);
          this.generateNewPlugin();
        })
        .catch(err => {
          console.log('Something went wrong creating app version', err);
        });
    }
  };

  updateExistingPluginFiles = async () => {
    const addinXML = this.updateAddinXML();
    const addinBlob = new Blob([addinXML], { type: 'text/xml' });

    const addinBlobClient = containerClient.getBlockBlobClient(
      `${this.updateDialogPlugin.name}/${this.updateDialogPlugin.shortName}.addin`
    );

    await addinBlobClient.uploadData(addinBlob, {
      // blockSize: 4 * 1024 * 1024, // 4MB block size
      concurrency: 20 // 20 concurrency
    });

    const addinManifest = this.updateAddinManifest();

    const manifestBlob = new Blob([JSON.stringify(addinManifest)], {
      type: 'application/json'
    });

    const manifestBlobClient = containerClient.getBlockBlobClient(
      `${this.updateDialogPlugin.name}/manifest.json`
    );

    const uploadManifestBlobResponse = await manifestBlobClient.uploadData(
      manifestBlob,
      {
        // blockSize: 4 * 1024 * 1024, // 4MB block size
        concurrency: 20 // 20 concurrency
      }
    );

    if (
      uploadManifestBlobResponse &&
      uploadManifestBlobResponse.clientRequestId
    ) {
      const pluginAppVersion = {
        appName: this.updateDialogPlugin.pluginName,
        version: this.updateDialogPlugin.version,
        publishedBy: this.rootStore.authStore.currentUser.email,
        releaseNotes: this.updateDialogPlugin.releaseNotes,
        updateLevel: this.updateLevel,
        releaseDate: databaseDate(),
        releaseTime: databaseTime(),

        required: true
      };

      const updateAppVersion = await this.rootStore.appVersionStore
        .create(pluginAppVersion)
        .catch(err => {
          console.log('Something went wrong creating app version', err);
        });
      if (updateAppVersion) {
        this.listCurrentCloudPlugins(this.updateDialogPlugin.name);
        this.resetExistingPluginDialog();
      }
    }
  };

  handlePluginFiles = async (event, folderName, fileName) => {
    const file = event.target.files[0];
    const files = Array.from(event.target.files);

    if (fileName && file.name !== fileName) throw Error('Not the right file');

    Promise.all(
      files.map(async file => {
        this.setCurrentFileUpload(file.name);
        const result = await this.uploadFileToBlob(file, file.name, folderName);

        return result;
      })
    ).then(() => {
      this.setCurrentFileUpload('');
      this.listCurrentCloudPlugins(folderName);
    });
  };
  handleSmallImages = async (event, folderName, fileName) => {
    const files = Array.from(event.target.files);
    Promise.all(
      files.map(async file => {
        return await this.uploadFileToBlob(file, file.name, folderName);
      })
    ).then(() => this.listCurrentCloudPlugins(folderName));
  };
  handleLargeImages = async (event, folderName, fileName) => {
    const files = Array.from(event.target.files);
    Promise.all(
      files.map(async file => {
        return await this.uploadFileToBlob(file, file.name, folderName);
      })
    ).then(result => {
      this.listCurrentCloudPlugins(folderName);
    });
  };
  incrementVersion = () => {
    const currentVersionBase = this.newPluginDialog.version.substr(
      0,
      this.newPluginDialog.version.lastIndexOf('.')
    );
    const newBuildNumber =
      parseInt(
        this.newPluginDialog.version.substr(
          this.newPluginDialog.version.lastIndexOf('.') + 1
        )
      ) + 1;
    this.handleNewPluginDialog(
      'version',
      `${currentVersionBase}.${newBuildNumber}`
    );
  };
  decrementVersion = () => {
    const currentVersionBase = this.newPluginDialog.version.substr(
      0,
      this.newPluginDialog.version.lastIndexOf('.')
    );
    const newBuildNumber =
      parseInt(
        this.newPluginDialog.version.substr(
          this.newPluginDialog.version.lastIndexOf('.') + 1
        )
      ) - 1;
    this.handleNewPluginDialog(
      'version',
      `${currentVersionBase}.${newBuildNumber}`
    );
  };

  incrementPluginVersion = () => {
    const version = this.updateDialogPlugin.version
      ? this.updateDialogPlugin.version
      : this.updateDialogPlugin.oldVersion;

    this.handleExistingPluginDialog('version', updateVersion(version));
  };
  decrementPluginVersion = () => {
    const version = this.updateDialogPlugin.version
      ? this.updateDialogPlugin.version
      : this.updateDialogPlugin.oldVersion;
    const currentVersionBase = version.substr(0, version.lastIndexOf('.'));
    const newBuildNumber =
      parseInt(version.substr(version.lastIndexOf('.') + 1)) - 1;
    this.handleExistingPluginDialog(
      'version',
      `${currentVersionBase}.${newBuildNumber}`
    );
  };

  uploadFileToBlob = async (file, fileName, pluginName) => {
    if (!file) return [];
    const blobClient = containerClient.getBlockBlobClient(
      `${pluginName}/${fileName}`
    );

    // upload file
    this.setCurrentFileUpload(fileName);
    const uploadResult = await blobClient.uploadData(file, {
      // blockSize: 4 * 1024 * 1024, // 4MB block size
      concurrency: 20 // 20 concurrency
    });
    if (uploadResult._response.status === 201) {
      this.addCurrentlyUploadedPluginFiles(file.name);
      this.setCurrentFileUpload('');

      if (fileName.includes('.png')) {
        const currentValue = fileName.replace('.png', '');
        this.availablePlugins[pluginName][currentValue] = blobClient.url;

        await this.listCurrentCloudPlugins(pluginName);
      }
      return { pluginName: pluginName, ...uploadResult };
    } else {
      this.setCurrentFileUpload('');
      this.rootStore.notificationStore.addNotification({
        message: `Failed to upload ${fileName}`,
        options: { variant: 'error' }
      });
    }
  };

  batchRemoveBlob = async (pluginName, pluginFiles) => {
    const blobClients = pluginFiles
      .slice()
      .map(blob => getContainerClient().getBlockBlobClient(blob));

    const result = await revitPluginBatchClient.deleteBlobs(blobClients);

    if (result._response.status === 202) {
      this.listCurrentCloudPlugins(pluginName);
      return true;
    } else {
      this.rootStore.notificationStore.addNotification({
        message: 'Failed to delete plugin',
        options: { variant: 'error' }
      });
    }
  };

  removeFileFromBlob = async (pluginName, fileName) => {
    const blobClient = containerClient.getBlockBlobClient(
      `${pluginName}/${fileName}`
    );
    const removedItem = await blobClient.delete();

    if (removedItem._response.status === 202) {
      await this.listCurrentCloudPlugins(pluginName);
    }
    return removedItem;
  };

  handleNewPluginDialog = (key, value) => {
    this.newPluginDialog[key] = value;
    // this.convert();
  };

  handleExistingPluginDialog = (key, value) => {
    this.existingPluginDialog[key] = value;
    // this.convert();
  };

  refreshCurrentPluginList = async blobList => {
    const allPlugins = blobList.slice().reduce((returnReduce, currentBlob) => {
      const pluginFolderName = currentBlob.name.split('/')[0];
      const pluginFileName = currentBlob.name.split('/')[1];

      if (!Object.keys(returnReduce).includes(pluginFolderName)) {
        returnReduce[pluginFolderName] = { files: [pluginFileName] };
      } else {
        returnReduce[pluginFolderName].files.push(pluginFileName);
      }
      return returnReduce;
    }, {});

    return allPlugins;
  };

  listCurrentCloudPlugins = async (plugin = '') => {
    let blobs = [];

    const blobContainerClient = getContainerClient();
    blobs = await blobContainerClient.listBlobsFlat();

    let blobList = [];
    for await (const blob of blobs) {
      if (plugin) {
        if (blob.name.includes(plugin)) {
          blobList.push(blob);
        }
      } else {
        blobList.push(blob);
      }
    }

    if (plugin) {
      const pluginToReplace = Object.keys(this.availablePlugins)
        .slice()
        .find(pluginKey => {
          return pluginKey === plugin;
        });

      if (pluginToReplace && blobList.length) {
        const pluginFiles = blobList
          .slice()
          .filter(blob => blob.name.includes(pluginToReplace));

        const pluginsReadable = readableJS(toJS(this.availablePlugins));

        const files = pluginFiles.slice().map(file => file.name.split('/')[1]);
        pluginsReadable[`${pluginToReplace}`].files = files;
        const updatedFiles = {
          ...pluginsReadable
        };
        runInAction(() => {
          this.availablePlugins = updatedFiles;
        });

        await this.addVersionToPluginList(updatedFiles);
        return updatedFiles;
      } else {
        const allPlugins = await this.refreshCurrentPluginList(blobList);
        const pluginsReadable = readableJS(toJS(this.availablePlugins));
        delete pluginsReadable[`${plugin}`];
        runInAction(() => {
          this.availablePlugins = { ...allPlugins, ...pluginsReadable };
        });
        await this.addVersionToPluginList(allPlugins);
        return allPlugins;
      }
    } else {
      const allPlugins = await this.refreshCurrentPluginList(blobList);
      runInAction(() => {
        this.availablePlugins = allPlugins;
      });

      await this.addVersionToPluginList(allPlugins);
      return allPlugins;
    }
  };

  addVersionToPluginList = async allPlugins => {
    const plugins = Array.from(Object.keys(allPlugins));

    return plugins.map(async currentPluginName => {
      let manifestInfo;
      let addinInfo;
      let imagePaths;
      let location;
      try {
        manifestInfo = await this.getManifest(currentPluginName);
      } catch (err) {
        manifestInfo = { version: 'Error' };
        console.error('Err w/ manifest', err);
      }
      try {
        addinInfo = await this.getAddinXML(currentPluginName);
      } catch (err) {
        console.error('Err w/ addinInfo', err);
      }
      const hasSmallImages = this.availablePlugins[currentPluginName]?.files
        ?.slice()
        .filter(
          file =>
            file.includes('SmallImage.png') || file.includes('smallImage.png')
        );
      const hasLargeImages = this.availablePlugins[currentPluginName]?.files
        ?.slice()
        .filter(
          file =>
            file.includes('LargeImage.png') || file.includes('largeImage.png')
        );
      try {
        imagePaths = await this.getImagePaths(
          currentPluginName,
          !!hasSmallImages,
          !!hasLargeImages
        );
      } catch (err) {
        console.error('Err w/ imagePaths', err);
      }
      const locationPluginName = currentPluginName
        .toLowerCase()
        .replaceAll(' ', '-');
      location = `/revit-plugins/${locationPluginName}`;

      runInAction(() => {
        if (location) {
          this.availablePlugins[currentPluginName].location = location;
        }
        if (addinInfo) {
          if (
            addinInfo &&
            addinInfo.RevitAddIns &&
            addinInfo.RevitAddIns.AddIn &&
            addinInfo.RevitAddIns.AddIn.AddInId &&
            addinInfo.RevitAddIns.AddIn.AddInId._text
          ) {
            const guid = addinInfo.RevitAddIns.AddIn.AddInId._text;
            this.availablePlugins[currentPluginName].guid = guid;
          }
        }
        if (imagePaths) {
          this.availablePlugins[currentPluginName].smallImages =
            imagePaths.smallImages;
          this.availablePlugins[currentPluginName].largeImages =
            imagePaths.largeImages;
        }

        if (manifestInfo)
          if (manifestInfo.version) {
            this.availablePlugins[currentPluginName].version =
              manifestInfo.version;
          }
        if (manifestInfo.developers) {
          this.availablePlugins[currentPluginName].developers =
            manifestInfo.developers;
        }
      });
      return currentPluginName;
    });
  };
  getImagePaths = async (pluginName, getSmallImage, getLargeImage) => {
    try {
      let smallImageUrls = [];
      let largeImageUrls = [];

      if (getSmallImage) {
        const allSmallImages = this.availablePlugins[pluginName].files
          .slice()
          .filter(
            file => file.includes('SmallImage') || file.includes('smallImage')
          );

        const allBlobClientPluginSmallImages = await Promise.all(
          allSmallImages.map(async image => {
            return await containerClient.getBlockBlobClient(
              `${pluginName}/${image}`
            );
          })
        );

        if (
          allBlobClientPluginSmallImages &&
          allBlobClientPluginSmallImages.length
        ) {
          smallImageUrls = allBlobClientPluginSmallImages.slice().map(image => {
            const urlParams = new URL(image.url).pathname.split('/');
            const imageName = urlParams.pop() || urlParams.pop();
            const imageNameDecoded = imageName.replace('%20', '');
            return { url: image.url, name: imageNameDecoded };
          });
        }
      }
      if (getLargeImage) {
        const allLargeImages = this.availablePlugins[pluginName].files
          .slice()
          .filter(
            file => file.includes('LargeImage') || file.includes('largeImage')
          );
        const allBlobClientPluginLargeImages = await Promise.all(
          allLargeImages.map(async image => {
            return await containerClient.getBlockBlobClient(
              `${pluginName}/${image}`
            );
          })
        );

        if (
          allBlobClientPluginLargeImages &&
          allBlobClientPluginLargeImages.length
        ) {
          largeImageUrls = allBlobClientPluginLargeImages.slice().map(image => {
            const urlParams = new URL(image.url).pathname.split('/');
            const imageName = urlParams.pop() || urlParams.pop();
            const imageNameDecoded = imageName.replace('%20', '');
            return { url: image.url, name: imageNameDecoded };
          });
        }
      }
      return Promise.resolve({
        smallImages: smallImageUrls,
        largeImages: largeImageUrls
      });
    } catch (err) {
      Promise.reject(err);
    }
  };
  getManifest = async pluginName => {
    try {
      const blockBlobClient = await containerClient.getBlockBlobClient(
        `${pluginName}/manifest.json`
      );

      const downloadBlockBlobResponse = await blockBlobClient.download(0);
      const readStream = await blobToString(
        await downloadBlockBlobResponse.blobBody
      );
      const versionInfo = await JSON.parse(readStream);
      return Promise.resolve(versionInfo);
    } catch (err) {
      Promise.reject(err);
    }
  };

  getAddinXML = async pluginName => {
    try {
      const pluginPath = `${pluginName}/${pluginName.replaceAll(
        ' ',
        ''
      )}.addin`;
      const blockBlobClient =
        await containerClient.getBlockBlobClient(pluginPath);

      const downloadBlockBlobResponse = await blockBlobClient.download(0);

      const readStream = await blobToString(
        await downloadBlockBlobResponse.blobBody
      );
      const addinJSON = JSON.parse(
        js2xml.xml2json(readStream, {
          compact: true,
          spaces: 4
        })
      );
      // const versionInfo = await JSON.parse(readStream);
      return Promise.resolve(addinJSON);
    } catch (err) {
      Promise.reject(err);
    }
  };

  handlePermission = async userId => {
    const { create, patch, get, entities } =
      this.rootStore.userPermissionsStore;
    const location = this.availablePlugins[
      this.manageUserDialog
    ]?.location?.replace('/revit-plugins', '');
    const foundPermission = entities
      .slice()
      .find(
        permission =>
          permission.location === location && permission.userId === userId
      );
    if (!foundPermission) {
      return await create({
        location: location,
        userId: userId,
        appName: '@compass/revit-plugins',
        findAllowed: true
      })
        .then(async created => await get(created.id))
        .catch(err => console.error('Error', err));
    }

    if (foundPermission.findAllowed) {
      return await patch(foundPermission.id, {
        findAllowed: false
      })
        .then(async () => await get(foundPermission.id))
        .catch(err => console.error('Error', err));
    } else {
      return await patch(foundPermission.id, {
        findAllowed: true
      })
        .then(async () => await get(foundPermission.id))
        .catch(err => console.error('Error', err));
    }
  };

  handleAllPermissions = async (allChecked, usersAllowed, usersNotAllowed) => {
    const { create, patch, find } = this.rootStore.userPermissionsStore;
    const location = this.availablePlugins[
      this.manageUserDialog
    ]?.location.replace('/revit-plugins', '');

    // In the case that all users have this plugins permission already
    if (allChecked && usersAllowed?.length > 0) {
      return Promise.all(
        usersAllowed.map(async currentUser => {
          await patch(currentUser.userPluginPermission.id, {
            findAllowed: false
          }).catch(err => console.error('Error', err));
        })
      ).then(
        async () =>
          await find({
            query: {
              location: location,
              appName: '@compass/revit-plugins'
            }
          })
      );
    }
    // Otherwise we assume we want to give all users missing permissions, permissions

    if (usersNotAllowed?.length > 0) {
      return Promise.all(
        usersNotAllowed.map(async currentUser => {
          if (!currentUser.userPluginPermission) {
            await create({
              location: location,
              userId: currentUser.id,
              appName: '@compass/revit-plugins',
              findAllowed: true
            });
          } else {
            await patch(currentUser.userPluginPermission.id, {
              findAllowed: true
            }).catch(err => console.error('Error', err));
          }
        })
      ).then(
        async () =>
          await find({
            query: {
              location: location,
              appName: '@compass/revit-plugins'
            }
          })
      );
    }
  };

  setCurrentDepartmentSelection = value => {
    this.currentDepartmentSelection = value;
  };

  setUpdateLevel = value => {
    this.updateLevel = value;
  };

  get allRevitPluginVersions() {
    return this.rootStore.appVersionStore.entities
      .slice()
      .filter(appVersion =>
        appVersion.appName.includes('@compass/revit-plugins')
      );
  }

  get availablePluginNames() {
    return Object.keys(this.availablePlugins).sort((a, b) =>
      a.localeCompare(b)
    );
  }

  get uploadedFiles() {
    return this.currentUploadedPluginFiles.slice();
  }
  get currentFileNameUploading() {
    return this.currentFileUpload || '';
  }

  get allDepartments() {
    const { entities: allUsers } = this.rootStore.userStore;
    return uniqueArray(allUsers.slice().map(user => user.department)).sort();
  }

  get filteredUsers() {
    const { entities: allUsers } = this.rootStore.userStore;
    return allUsers
      .slice()
      .filter(user => user.department === this.currentDepartmentSelection);
  }

  get pluginPermissionUsers() {
    const { entities: allPermissions } = this.rootStore.userPermissionsStore;
    if (
      !this.filteredUsers ||
      !this.filteredUsers.length ||
      !this.availablePlugins[this.manageUserDialog]
    )
      return [];

    const users = this.filteredUsers
      .slice()
      .filter(user => {
        if (!user || !user.email) return false;
        if (!this.manageUsersFilter) return true;
        const userEmail = user.email.toLowerCase();
        const filterEmail = this.manageUsersFilter.toLowerCase();
        return userEmail.includes(filterEmail);
      })
      .map(user => {
        const location = this.availablePlugins[
          this.manageUserDialog
        ]?.location?.replace('/revit-plugins', '');

        const userPluginPermission = allPermissions
          .slice()
          .find(
            permission =>
              location &&
              permission.location === location &&
              user.id === permission.userId
          );

        return {
          userPluginPermission: userPluginPermission
            ? toJS(userPluginPermission)
            : null,
          ...user
        };
      });
    return [{ email: 'All Users' }, ...users];
  }

  get managePluginPermissionLocation() {
    if (!this.availablePlugins[this.manageUserDialog]?.location) return null;
    return this.availablePlugins[this.manageUserDialog].location.replace(
      '/revit-plugins',
      ''
    );
  }

  get manageUserDialog() {
    return (
      this.rootStore.routerStore?.queryParams?.dialogName ===
        'Manage Permissions' &&
      this.rootStore.routerStore?.queryParams?.dialogContext
    );
  }

  get updateDialogPlugin() {
    if (
      this.rootStore.routerStore?.queryParams?.dialogName === 'Update Plugin' &&
      this.rootStore.routerStore?.queryParams?.dialogContext
    ) {
      const currentPluginName =
        this.rootStore.routerStore?.queryParams?.dialogContext;
      const shortName = currentPluginName.replaceAll(' ', '');
      const pluginName = `@compass/revit-plugins/${shortName}`;
      const pluginCloudData = this.availablePlugins[currentPluginName];

      let currentPlugin = {
        name: currentPluginName,
        developers: pluginCloudData?.developers || '',
        guid: pluginCloudData?.guid,
        oldVersion: pluginCloudData?.version || '',
        version: pluginCloudData?.version
          ? updateVersion(pluginCloudData?.version)
          : '',
        shortName: shortName,
        smallImageUrls: pluginCloudData?.smallImages || [],
        largeImageUrls: pluginCloudData?.largeImages || [],
        pluginName: pluginName,

        releaseNotes: ''
      };

      if (Object.keys(this.existingPluginDialog).length > 2) {
        const { shortName, pluginName, ...updateData } =
          this.existingPluginDialog;

        for (const [key, value] of Object.entries(updateData)) {
          currentPlugin[key] = value;
        }
      }

      return currentPlugin;
    }
    return null;
  }
}

export default RevitPluginStore;
