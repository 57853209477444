import { sortDateAsc, uniqueArray } from '@compass/utils';
import { makeAutoObservable } from 'mobx';

class DockerVersionsStore {
  onlyLatest = true;
  filter = '';

  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  setFilter = value => {
    this.filter = value;
  };

  setOnlyLatest = () => {
    this.onlyLatest = !this.onlyLatest;
  };

  get currentProjectName() {
    return this.rootStore?.devProjectManagementStore?.currentDevProject
      ?.projectName;
  }

  get dockerVersions() {
    let allDockerVersions = this.rootStore.webhooksDockerStore.entities
      .slice()
      .filter(project => {
        return project.appName === this.currentProjectName;
      })
      .sort((a, b) => sortDateAsc(a.releaseDate, b.releaseDate));

    return allDockerVersions;
  }

  get allAppNames() {
    return uniqueArray(
      this.rootStore.webhooksDockerStore.entities.slice().map(i => i.appName)
    );
  }
}

export default DockerVersionsStore;
