import SageViewerIcon from '@mui/icons-material/CloudSync';

const sageViewerRoutes = [
  {
    path: '/sage-viewer',
    layout: '/app',
    name: 'Sage Viewer',
    icon: SageViewerIcon,
    component: () => import('./SageViewer.Container'),
    requiresPermission: false,
    homeShortcut: true,
    sideBarShortcut: true,
    // channels: ['?sageService'],
    beforeEnter: async ({ rootStore }) => {
      rootStore.sageViewerStore.findServiceStores();
    }
  }
];

export default sageViewerRoutes;
