import { makeAutoObservable } from 'mobx';

class LogViewerStore {
  levels = ['info', 'debug', 'warning', 'error'];
  info = true;
  debug = true;
  warning = true;
  error = true;
  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  setLevelVisibility = (level, value) => {
    this[level] = value;
  };

  get allAvailableApps() {
    const {
      developmentProjectStore: { entities }
    } = this.rootStore;
    if (!entities || !entities.length) return [];

    return entities.slice();
  }

  get currentAppLogEntities() {
    const {
      logStore: { entities: currentAppLogEntities },
      routerStore: { params }
    } = this.rootStore;
    return currentAppLogEntities
      .slice()
      .filter(
        log => log.appName && log.appName.includes(params?.currentProject)
      )
      .filter(log => {
        if (!this.info && log.level === 'info') {
          return false;
        }
        if (!this.debug && log.level === 'debug') {
          return false;
        }
        if (!this.warning && log.level === 'warning') {
          return false;
        }
        if (!this.error && log.level === 'error') {
          return false;
        }

        return true;
      })
      .map(i => {
        return { ...i };
      });
  }
}

export default LogViewerStore;
