import FindAllStore from './findAllStore';
import UIStore from './uiStore';
// import BarcodeCameraStore from './barcodeCameraStore';

const allUtilsStores = [
  { name: 'uiStore', store: UIStore },
  { name: 'findAllStore', store: FindAllStore }
  // { name: 'barcodeCameraStore', store: BarcodeCameraStore }
];
export default allUtilsStores;
