import { makeAutoObservable } from 'mobx';

class DashboardStore {
  loadedShortcuts = [];
  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
    this.loadedShortcuts = this.getStorageItems();
  }
  getStorageItems = () => {
    const foundItems = localStorage.getItem('device-shortcuts');
    if (foundItems) {
      return JSON.parse(foundItems);
    }
    return [];
  };

  saveShortcuts = () => {
    return localStorage.setItem(
      'device-shortcuts',
      JSON.stringify([
        ...this.loadedShortcuts.map(i => {
          return { ...i };
        })
      ])
    );
  };

  setShortcuts = value => {
    this.loadedShortcuts = [...this.loadedShortcuts, value];
    return this.saveShortcuts();
  };

  removeShortcut = columnIndex => {
    this.loadedShortcuts.splice(columnIndex, 1);
    return this.saveShortcuts();
  };

  get deviceShortcuts() {
    return this.loadedShortcuts.map(i => {
      return { ...i };
    });
  }
}

export default DashboardStore;
