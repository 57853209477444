import { uriToCamel } from '@compass/utils';
import { makeAutoObservable, reaction } from 'mobx';

class StoreVisualizerStore {
  viewItems = false;
  viewItemsValueFilter = '';
  viewItemsKeyFilter = '';
  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;

    reaction(
      () => this.storeName,
      name => {
        name && this.resetStoreItems();
      },
      { fireImmediately: true }
    );
  }

  resetStoreItems = () => {
    console.log('resetStoreItems called!!');
    this.viewItems = false;
    this.viewItemsKeyFilter = '';
    this.viewItemsValueFilter = '';
  };

  setViewItems = () => {
    this.viewItems = !this.viewItems;
  };

  setViewItemsValueFilter = value => {
    this.viewItemsValueFilter = value;
  };

  setViewItemsKeyFilter = value => {
    if (this.viewItemsValueFilter) {
      this.setViewItemsValueFilter('');
    }
    this.viewItemsKeyFilter = value;
  };

  toJSON(store) {
    let jsonObj = Object.assign({}, store);
    if (jsonObj.rootStore) {
      delete jsonObj.rootStore;
    }
    const proto = Object.getPrototypeOf(store);
    for (const key of Object.getOwnPropertyNames(proto)) {
      const desc = Object.getOwnPropertyDescriptor(proto, key);
      const hasGetter = desc && typeof desc.get === 'function';
      if (hasGetter) {
        jsonObj[key] = store[key];
      }
    }

    for (const key of Object.keys(jsonObj)) {
      if (typeof jsonObj[key] === 'function') {
        jsonObj[key] = 'ACTION';
      }
    }
    return jsonObj;
  }

  get hasItems() {
    return 'items' in this.selectedStore;
  }

  get storeItemCount() {
    if (!this.hasItems) return null;
    return this.selectedStore.items.length;
  }

  get renderStore() {
    if (!this.selectedStore) return null;

    if (this.hasItems)
      return JSON.stringify(
        this.toJSON({
          ...this.selectedStore,
          items: this.selectedStore?.items?.length || 0
        }),
        null,
        2
      );
    return JSON.stringify(this.toJSON(this.selectedStore), null, 2);
  }

  get filteredStoreItems() {
    if (this.viewItemsKeyFilter && this.viewItemsValueFilter) {
      return this.selectedStore.items
        .slice()
        .filter(i =>
          this.viewItemsKeyFilter === 'id'
            ? parseInt(i[this.viewItemsKeyFilter]) ===
              parseInt(this.viewItemsValueFilter)
            : String(i[this.viewItemsKeyFilter]).includes(
                String(this.viewItemsValueFilter)
              )
        );
    }
    return this.selectedStore.items.slice();
  }

  get filteredStoreItemsCount() {
    if (!this.filteredStoreItems) return 0;
    return this.filteredStoreItems.length;
  }

  get renderStoreItems() {
    if (!this.selectedStore) return null;
    if (!this.viewItems) return null;

    return JSON.stringify(this.filteredStoreItems, null, 2);
  }

  get storesAvailable() {
    return Object.keys(this.rootStore)
      .filter(rootStoreValue => rootStoreValue.includes('Store'))
      .sort();
  }

  get selectedStoreItemKeys() {
    if (!this.hasItems || !this.selectedStore.items.length) return null;
    return Object.keys(this.selectedStore.items[0]).sort();
  }

  get storeName() {
    if (!this.rootStore?.routerStore?.params?.store) return '';
    const serviceName = uriToCamel(this.rootStore.routerStore.params.store);
    console.log('serviceName', serviceName);
    return serviceName + 'Store';
  }

  get selectedStore() {
    if (this.storeName && this.rootStore[this.storeName]) {
      const {
        app,
        service,
        rootStore: dropRoot,
        ...store
      } = this.rootStore[this.storeName];
      if (this.storeName === 'notificationStore') {
        const { notiRef, ...notiStore } = this.rootStore[this.storeName];
        return { ...notiStore };
      }
      if (this.storeName === 'authStore') {
        const { userService, tokenStoreService, ...authStore } = store;
        return { ...authStore };
      }
      if (this.storeName === 'channelStore') {
        const { channelService, ...channelStore } = store;
        return { ...channelStore };
      }
      if (this.storeName === 'queryStore') {
        const { currentServer, devQueryStore, ...queryStore } = store;
        return { ...queryStore };
      }
      if (this.storeName === 'uiStore') {
        const { services, ...uiStore } = store;
        return { ...uiStore };
      }

      return { ...store };
    }
    return {};
  }
}

export default StoreVisualizerStore;
