import compassPluginManagerRoutes from './CompassPluginManager/compassPluginManagerRoutes';
import dockerLogsRoutes from './DockerLogs/dockerLogsRoutes';
import serviceStatsRoutes from './ServiceStats/serviceStatsRoutes';

const projectSpecificRoutes = [
  ...compassPluginManagerRoutes,
  ...dockerLogsRoutes,
  ...serviceStatsRoutes
];

export default projectSpecificRoutes;
