import DockerVersionIcon from '@mui/icons-material/FormatListNumbered';

const dockerVersionsRoutes = [
  {
    path: '/project/:currentProject/docker-versions',
    subRoutes: ['ProjectDashboard'],
    layout: '/app',
    name: 'Docker Versions',
    icon: DockerVersionIcon,
    component: () => import('./DockerVersions.Container'),
    requiresPermission: false,
    homeShortcut: true,
    projectRoute: true,
    sideBarShortcut: true,
    subscription: { type: 'docker', notificationType: 'push' },
    channels: ['webhooks/docker', 'user-subscription'],
    beforeEnter: async ({ rootStore, params }) => {
      const {
        webhooksDockerStore: { find: findDockerVersions },
        userSubscriptionStore: { find: findUserSubscriptions },
        authStore: { currentUser }
      } = rootStore;
      findUserSubscriptions({
        userId: currentUser.id,
        notificationType: 'push',
        filter: 'docker',
        appName: '@compass/' + params?.currentProject
      });
      findDockerVersions({
        query: {
          $sort: {
            releaseDate: -1
          },
          $limit: 2000,
          appName: '@compass/' + params?.currentProject
        }
      });
    }
  }
];

export default dockerVersionsRoutes;
