import { camelToTitle, isSameId } from '@compass/utils';
import { makeAutoObservable, reaction, runInAction } from 'mobx';

class SageViewerStore {
  loading = false;
  availableServices = [];

  sortState = {};
  filterState = {};

  currentServiceItems = {};

  sageJobId = '';
  sageVendorId = '';

  debugMode = false;

  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;

    this.sageServiceHeadersDispose = reaction(
      () => this.currentHeaders,
      currentHeaders => {
        if (currentHeaders?.length) {
          const newColumnHeaders = currentHeaders.map(headerKey => {
            let type = 'string';
            if (
              [
                'selectedWorkForceTypes',
                'documentHeaderId',
                'relatedInvoices'
              ].includes(headerKey)
            ) {
              return {
                key: headerKey,
                title: camelToTitle(headerKey),
                dataType: type,
                width: 250
              };
            }
            if (headerKey === 'isDebit') {
              return {
                key: headerKey,
                title: camelToTitle(headerKey),
                dataType: 'boolean'
              };
            }
            return {
              key: headerKey,
              title: camelToTitle(headerKey),
              dataType: type
            };
          });
          if (this.rootStore.dataGridStore?.namespaces?.SageViewer)
            this.rootStore.dataGridStore.namespaces.SageViewer.setColumnHeaders(
              newColumnHeaders
            );
        }
      },
      { delay: 200 }
    );

    this.sageBudgetDialogDispose = reaction(
      () => this.sageBudgetEntriesDialogHeaders,
      currentDialogHeaders => {
        if (currentDialogHeaders?.length) {
          const newDialogColumnHeaders = currentDialogHeaders.map(headerKey => {
            let type = 'string';
            if (
              [
                'selectedWorkForceTypes',
                'documentHeaderId',
                'relatedInvoices'
              ].includes(headerKey)
            ) {
              return {
                key: headerKey,
                title: camelToTitle(headerKey),
                dataType: type,
                width: 250
              };
            }
            if (headerKey === 'isDebit') {
              return {
                key: headerKey,
                title: camelToTitle(headerKey),
                dataType: 'boolean'
              };
            }
            return {
              key: headerKey,
              title: camelToTitle(headerKey),
              dataType: type
            };
          });
          if (this.rootStore.dataGridStore?.namespaces?.SageViewerBudgetDialog)
            this.rootStore.dataGridStore.namespaces.SageViewerBudgetDialog.setColumnHeaders(
              newDialogColumnHeaders
            );
        }
      },
      { delay: 200 }
    );
  }

  dispose = () => {
    this.sageServiceHeadersDispose && this.sageServiceHeadersDispose();
    this.sageBudgetDialogDispose && this.sageBudgetDialogDispose();
  };

  setupChannelListeners = sageService => {
    this.rootStore.app.service(sageService).on('created', created => {
      if (this.currentServiceItems[this.selectedService]?.data) {
        this.currentServiceItems[this.selectedService].data.push(created);
      }
    });
    this.rootStore.app.service(sageService).on('patched', patched => {
      if (this.currentServiceItems[this.selectedService]?.data) {
        const foundIndex = this.currentServiceItems[
          this.selectedService
        ].data.findIndex(item => isSameId(item.id, patched.id));
        if (foundIndex > -1) {
          this.currentServiceItems[this.selectedService].data[foundIndex] =
            patched;
        }
      }
    });
    this.rootStore.app.service(sageService).on('removed', removed => {
      if (this.currentServiceItems[this.selectedService]?.data) {
        const newArray = this.currentServiceItems[
          this.selectedService
        ].data.filter(item => !isSameId(item.id, removed.id));
        this.currentServiceItems[this.selectedService].data.replace(newArray);
      }
    });
  };

  setSelectedService = (sageService, needsRouting = true) => {
    this.loading = true;
    if (!this.rootStore.app.service(sageService)) {
      throw new Error('That service is not available', sageService);
    }
    if (needsRouting)
      this.rootStore.routerStore.goTo(null, null, { sageService: sageService });
    runInAction(() => {
      this.sortState = {};
      this.filterState = {};
    });
    if (
      sageService === 'sage/project-contract' ||
      sageService === 'sage/budget' ||
      sageService === 'sage/purchase-orders' ||
      sageService === 'sage/cost-data' ||
      sageService === 'sage/shop-labor' ||
      sageService === 'sage/field-labor'
    ) {
      this.debugMode && console.log('Hitting block for requiresMoreParameters');
      runInAction(() => {
        this.requiresMoreParameters = true;
        this.findServiceItems('sage/job').then(() => {
          runInAction(() => {
            //TODO remove - just for testing
            this.setSageJobId('J00000139');
            this.loading = false;
          });
        });
      });
      return sageService;
    }
    if (sageService === 'sage/vendors') {
      this.debugMode && console.log('Hitting block for requiresMoreParameters');
      runInAction(() => {
        this.requiresMoreParameters = true;
        this.findServiceItems('sage/job').then(() => {
          runInAction(() => {
            //TODO remove - just for testing
            this.setSageVendor('V-000990');
            this.loading = false;
          });
        });
      });
      return sageService;
    }
    runInAction(() => {
      this.requiresMoreParameters = false;
    });
    this.findServiceItems(sageService).then(() => {
      this.setupChannelListeners(sageService);
      runInAction(() => {
        this.loading = false;
      });
    });

    // Need to add event handlers for created/patched/removed
    return sageService;
  };

  findServiceStores = async () => {
    const foundServices = await this.rootStore.servicesStore
      .find()
      .catch(err => {
        console.log('something went wrong finding services', err);
      });
    if (foundServices?.serviceIDs?.length > 0) {
      runInAction(() => {
        this.availableServices = foundServices?.serviceIDs
          .slice()
          .filter(i => {
            if (i.name.includes('sage')) return true;

            return false;
          })
          .sort((a, b) => (a.name < b.name ? -1 : 1));
        this.debugMode &&
          console.log('this.availableServices', this.availableServices);
        if (!this.selectedService && this.availableServices.length) {
          this.debugMode &&
            console.log('this.selectedService', this.selectedService);
          this.setSelectedService(this.availableServices[0].name);
          this.debugMode &&
            console.log('this.selectedService', this.selectedService);
          return;
        }
        if (
          this.selectedService &&
          !this.currentServiceItems[this.selectedService]
        ) {
          this.debugMode &&
            console.log('this.selectedService2', this.selectedService);
          this.setSelectedService(this.selectedService, false);
          this.debugMode &&
            console.log('this.selectedService2', this.selectedService);
          return;
        }
      });
    }
  };

  findServiceItems = async sageServiceName => {
    let foundItems;
    this.debugMode && console.log('sageServiceName', sageServiceName);
    if (
      sageServiceName === 'sage/budget' ||
      sageServiceName === 'sage/project-contract' ||
      sageServiceName === 'sage/purchase-orders' ||
      sageServiceName === 'sage/cost-data' ||
      sageServiceName === 'sage/shop-labor' ||
      sageServiceName === 'sage/field-labor'
    ) {
      foundItems = await this.rootStore.app
        .service(sageServiceName)
        .find({ query: { jobId: this.sageJobId } })
        .catch(err => {
          this.rootStore.notificationStore.addNotification({
            message: err.message,
            options: { variant: 'warning' }
          });
          console.error(
            'something went wrong finding sageServiceName',
            sageServiceName
          );
          console.error('something went wrong finding service items', err);
        });
    } else if (sageServiceName === 'sage/vendors') {
      this.debugMode && console.log('finding with sageVendorId');
      foundItems = await this.rootStore.app
        .service(sageServiceName)
        .find({ query: { vendorId: this.sageVendorId } })
        .catch(err => {
          this.rootStore.notificationStore.addNotification({
            message: err.message,
            options: { variant: 'warning' }
          });
          console.error(
            'something went wrong finding sageServiceName',
            sageServiceName
          );
          console.error('something went wrong finding service items', err);
        });
    } else {
      this.debugMode && console.log('finding with no params');
      foundItems = await this.rootStore.app
        .service(sageServiceName)
        .find()
        .catch(err => {
          this.rootStore.notificationStore.addNotification({
            message: err.message,
            options: { variant: 'warning' }
          });
          console.error(
            'something went wrong finding sageServiceName',
            sageServiceName
          );
          console.error('something went wrong finding service items', err);
        });
    }

    runInAction(() => {
      this.debugMode && console.log('foundItems', foundItems);
      if (foundItems?.total > 0) {
        runInAction(() => {
          this.currentServiceItems[sageServiceName] = {
            total: foundItems?.total,
            data: []
          };
        });
      }
      if (foundItems?.data?.length > 0) {
        this.currentServiceItems[sageServiceName].data = foundItems.data;
        this.currentServiceItems[sageServiceName].total = foundItems.total;
      }
      this.sortState = {
        sortBy: [],
        sortDirection: {}
      };
    });
  };

  setSageJobId = value => {
    this.sageJobId = value;

    this.findServiceItems(this.selectedService);
  };

  setSageVendor = value => {
    this.sageVendorId = value;

    this.findServiceItems(this.selectedService);
  };

  setFilter = (columnKey, filterValue) => {
    if (filterValue === '' && this.filterState?.[columnKey]) {
      delete this.filterState[columnKey];
      return;
    }
    this.filterState[columnKey] = filterValue;
  };

  handleMultiSort = ({ sortBy, sortDirection }) => {
    if (this.sortState.sortBy.includes(sortBy)) {
      runInAction(() => {
        this.sortState.sortDirection[sortBy] = sortDirection;
      });
      return this.sortState;
    }
    if (this.sortState?.sortBy?.length === 4) {
      return this.rootStore.notificationStore.addNotification({
        message: 'You can only sort up to 4 columns',
        options: { variant: 'info' }
      });
    }
    runInAction(() => {
      this.sortState.sortBy.push(sortBy);
      this.sortState.sortDirection[sortBy] = sortDirection;
    });
    return this.sortState;
  };

  removeSortBy = sortBy => {
    runInAction(() => {
      this.sortState.sortBy = this.sortState.sortBy.filter(
        sorted => !sorted.includes(sortBy)
      );
      delete this.sortState.sortDirection[sortBy];
    });
  };

  get availableSageJobIds() {
    return (
      this.currentServiceItems['sage/job']?.data
        ?.map(i => i.projectId)
        .sort() || []
    );
  }

  get selectedService() {
    const { routerStore } = this.rootStore;
    return routerStore?.queryParams?.sageService || '';
  }

  get availableServiceNames() {
    return this.availableServices?.slice().map(i => i.name);
  }

  get currentServiceEntities() {
    if (!this.currentServiceItems[this.selectedService]?.data) return [];
    return this.currentServiceItems[this.selectedService].data.slice();
  }

  get currentDataGridEntities() {
    if (!this.currentServiceEntities?.length) return [];
    return this.currentServiceEntities.slice().map(item => {
      if (item?.entries?.length) {
        return { ...item, entries: `${item.entries.length} Entries` };
      }
      return { ...item };
    });
  }

  get currentHeaders() {
    if (
      !this.currentServiceItems[this.selectedService]?.data ||
      !this.currentServiceItems[this.selectedService]?.data[0]
    )
      return [];
    const firstEntityKeys = Object.keys(
      this.currentServiceItems[this.selectedService]?.data[0]
    );
    if (firstEntityKeys.includes('id')) {
      const currentKeys = firstEntityKeys
        .slice()
        .filter(i => i !== 'id')
        .sort((a, b) => (a < b ? -1 : 1));
      return ['id', ...currentKeys];
    }

    return [...firstEntityKeys];
  }

  get sageBudgetEntriesDialogEntities() {
    const {
      routerStore: { queryParams }
    } = this.rootStore;
    const foundBudget = this.currentServiceEntities.find(
      i => i.budgetId === queryParams?.dialogContext
    );
    this.debugMode && console.log('foundBudget', foundBudget);
    return foundBudget?.entries?.length
      ? foundBudget?.entries.map(item => {
          return { ...item };
        })
      : [];
  }

  get sageBudgetEntriesDialogHeaders() {
    if (
      !this.rootStore.dataGridStore?.namespaces?.SageViewerBudgetDialog ||
      !this.sageBudgetEntriesDialogEntities ||
      !this.sageBudgetEntriesDialogEntities[0]
    )
      return [];
    const firstEntityKeys = Object.keys(
      this.sageBudgetEntriesDialogEntities[0]
    );
    if (firstEntityKeys.includes('id')) {
      const currentKeys = firstEntityKeys
        .slice()
        .filter(i => i !== 'id')
        .sort((a, b) => (a < b ? -1 : 1));
      return ['id', ...currentKeys];
    }

    return [...firstEntityKeys];
  }
}

export default SageViewerStore;
