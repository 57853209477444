const serviceStores = [
  {
    name: 'services',
    options: { enableStorage: false, enableErrorNotifications: true }
  },
  {
    name: 'panel-revit',
    options: {
      debugMode: false,
      enableStorage: true,
      enableErrorNotifications: true
    }
  },
  {
    name: 'panel-plant',
    options: {
      debugMode: false,
      enableStorage: true,
      enableErrorNotifications: true
    }
  },
  {
    name: 'panel-yard',
    options: {
      debugMode: false,
      enableStorage: true,
      enableErrorNotifications: true
    }
  },
  {
    name: 'panel-field',
    options: {
      debugMode: false,
      enableStorage: true,
      enableErrorNotifications: true
    }
  },

  {
    name: 'ifc-status',
    options: { enableStorage: true, enableErrorNotifications: true }
  },
  {
    name: 'hold',
    options: {
      enableStorage: true,
      enableErrorNotifications: true,
      apiVersion: 2
    }
  },
  {
    name: 'trailer',
    options: { enableStorage: true, enableErrorNotifications: true }
  },
  {
    name: 'scheduling-day',
    options: { enableStorage: true, enableErrorNotifications: true }
  },
  {
    name: 'job-casting-estimate',
    options: { enableStorage: true, enableErrorNotifications: true }
  },
  {
    name: 'job',
    options: { enableStorage: true, enableErrorNotifications: true }
  },
  {
    name: 'job-plant',
    options: { enableStorage: true, enableErrorNotifications: true }
  },
  {
    name: 'load',
    options: { enableStorage: true, enableErrorNotifications: true }
  },

  // {
  //   name: 'hardware',
  //   options: { enableStorage: true, enableErrorNotifications: true }
  // },
  {
    name: 'hardware-orders',
    options: { enableStorage: true, enableErrorNotifications: true }
  },
  {
    name: 'absorption',
    options: { enableStorage: true, enableErrorNotifications: true }
  },
  {
    name: 'daily-compression',
    options: { enableStorage: true, enableErrorNotifications: true }
  },
  {
    name: 'length-change',
    options: { enableStorage: true, enableErrorNotifications: true }
  },
  {
    name: 'pre-post-pour',
    options: { enableStorage: true, enableErrorNotifications: true }
  },
  // All other sync-able services

  {
    name: 'comments',
    options: {
      enableStorage: true,
      enableErrorNotifications: true,
      apiVersion: 2
    }
  },
  {
    name: 'cost-codes',
    options: { enableStorage: true, enableErrorNotifications: true }
  },
  {
    name: 'dates/holidays',
    options: { enableStorage: true, enableErrorNotifications: true }
  },
  {
    name: 'design-values',
    options: { enableStorage: true, enableErrorNotifications: true }
  },
  {
    name: 'departments',
    options: { enableStorage: true, enableErrorNotifications: true }
  },
  {
    name: 'email/history',
    options: { enableStorage: true, enableErrorNotifications: true }
  },
  {
    name: 'sieve-analysis',
    options: { enableStorage: true, enableErrorNotifications: true }
  },
  {
    name: 'wet-test',
    options: { enableStorage: true, enableErrorNotifications: true }
  },
  {
    name: 'casting-map-layout',
    options: { enableStorage: true, enableErrorNotifications: true }
  },
  {
    name: 'plant/casting-limits',
    options: { enableStorage: true, enableErrorNotifications: true }
  },
  {
    name: 'hardware-order-details',
    options: { enableStorage: true, enableErrorNotifications: true }
  },
  { name: 'monitors/devices', enableErrorNotifications: true },
  { name: 'monitors/control', enableErrorNotifications: true },
  {
    name: 'update-history',
    options: {
      enableStorage: true,
      enableErrorNotifications: true,
      apiVersion: 2
    }
  },
  {
    name: 'azure/devops/projects',
    options: {
      enableStorage: false,
      enableErrorNotifications: true,
      debugMode: false
    }
  },
  {
    name: 'azure/devops/work',
    options: {
      enableStorage: false,
      enableErrorNotifications: true,
      debugMode: false
    }
  },
  {
    name: 'azure/devops/git',
    options: {
      enableStorage: false,
      enableErrorNotifications: true,
      debugMode: false
    }
  },
  {
    name: 'azure/devops/build',
    options: {
      enableStorage: false,
      enableErrorNotifications: true,
      debugMode: false
    }
  },

  {
    name: 'development/projects',
    options: { enableStorage: true, enableErrorNotifications: true }
  },
  {
    name: 'webhooks/docker',
    options: { enableStorage: true, enableErrorNotifications: true }
  },

  {
    name: 'push-device-connection',
    options: {
      enableStorage: false,
      enableErrorNotifications: true,
      apiVersion: 2
    }
  },
  {
    name: 'push-notification',
    options: { enableStorage: false, enableErrorNotifications: true }
  },
  {
    name: 'push-trigger-send',
    options: { enableStorage: false, enableErrorNotifications: true }
  },
  {
    name: 'docker-logs',
    options: {
      enableStorage: false,
      enableErrorNotifications: true,
      idField: 'uuid'
    }
  },
  {
    name: 'app-insights',
    options: {
      enableStorage: true,
      enableErrorNotifications: true
    }
  },
  {
    name: 'notification-subscriptions',
    options: {
      enableStorage: false,
      enableErrorNotifications: true
    }
  },
  {
    name: 'service-stats',
    options: {
      enableStorage: false,
      enableErrorNotifications: true
    }
  },
  {
    name: 'report/panel-hold',
    options: {
      enableStorage: false,
      enableErrorNotifications: true
    }
  }
];
export default serviceStores;
