import DashboardStore from './dashboardStore';
import DatabaseSyncStore from './databaseSyncStore';
import DbAuditStore from './dbAuditStore';
import EntityViewerStore from './entityViewerStore';
import MonitorManagementStore from './monitorManagementStore';
import ReportViewerStore from './reportViewerStore';
import SageViewerStore from './sageViewerStore';
import StoreVisualizerStore from './storeVisualizerStore';
import TokenManagementStore from './tokenManagementStore';
import UpdateHistoryViewStore from './updateHistoryViewStore';
import UserPermissionManagementStore from './userPermissionManagementStore';

const allPageStores = [
  {
    name: 'userPermissionManagementStore',
    store: UserPermissionManagementStore
  },
  { name: 'monitorManagementStore', store: MonitorManagementStore },
  { name: 'tokenManagementStore', store: TokenManagementStore },
  { name: 'storeVisualizerStore', store: StoreVisualizerStore },
  { name: 'databaseSyncStore', store: DatabaseSyncStore },
  { name: 'dbAuditStore', store: DbAuditStore },
  { name: 'updateHistoryViewStore', store: UpdateHistoryViewStore },
  { name: 'entityViewerStore', store: EntityViewerStore },
  { name: 'sageViewerStore', store: SageViewerStore },
  { name: 'dashboardStore', store: DashboardStore },
  { name: 'reportViewerStore', store: ReportViewerStore }
];
export default allPageStores;
