import { getOffsetDate } from '@compass/utils';
import { isAfter } from 'date-fns';
import { makeAutoObservable } from 'mobx';

class TokenManagementStore {
  onlyInvalid = false;
  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }
  setOnlyInvalid = () => {
    this.onlyInvalid = !this.onlyInvalid;
  };
  get filteredTokens() {
    const {
      refreshTokenStore: { entities }
    } = this.rootStore;
    const tokenEntities = entities
      .slice()
      .filter(token => token.id)
      .map(item => {
        const today = new Date();
        const createdExpire = getOffsetDate(
          new Date(item.createdDate + 'T' + item.createdTime),
          {
            offset: 365
          }
        );
        const isExpired = isAfter(today, new Date(createdExpire));
        const foundUser = this.rootStore.userStore.entities
          .slice()
          .find(user => user.id === item.userId);
        if (foundUser) {
          return {
            ...item,
            isExpired,
            currentUser: foundUser.email,
            expiredDate: createdExpire
          };
        }
        return {
          ...item,
          isExpired: isExpired,
          currentUser: item.userId,
          expiredDate: createdExpire
        };
      });
    if (this.onlyInvalid)
      return tokenEntities.filter(item => {
        if (!item.isValid) {
          return true;
        }
        if (item.isExpired) {
          return true;
        }
        return false;
      });
    return tokenEntities;
  }
}

export default TokenManagementStore;
