// @ts-check
import { setupInjectStores } from '@compass/react';
import { RootStore } from '@compass/store';
import localForage from 'localforage';

import routesList from 'views/routesList';

import packageJson from '../../package.json';
import appStores from './appStores/allAppStores';
import serviceStores from './serviceStores';

let defaultRouteLocation = 'Dashboard';

setupInjectStores(appStores, serviceStores);

const rootStore = new RootStore({
  appName: packageJson.name,
  storage: localForage,
  mode: import.meta.env.MODE === 'development' ? 'development' : 'prod',
  appVersion: packageJson.version,
  appTitle: 'Dev Tools',
  asyncStorage: true,
  autoUpdate: true,
  enableNotificationStore: true,
  enableRequestQueue: true,
  enableWebApiStore: true,
  enableBarcodeCameraStore: true,
  enableDataGridStore: true,
  viewStoreProps: { enableNotifications: true },
  // enableFindAllStore: true,
  syncChannels: true,
  apiOptions: {
    debugMode: false,
    authVersion: 2,
    uriOverride: {
      development: 'http://localhost:3030'
    },
    enableFindOne: true
  },
  enableAuthStore: { debugMode: false },
  channelStoreDebugMode: false,
  enableRouterStore: {
    defaultRoute: defaultRouteLocation,
    debugMode: false,
    routesList: routesList,
    historyEnabled: true
  },
  serviceStores: serviceStores,
  appStores: appStores
});

export default rootStore;
