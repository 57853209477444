import DBAuditIcon from '@mui/icons-material/AssignmentTurnedIn';

const databaseAuditRoutes = [
  {
    path: '/db-audit',
    layout: '/app',
    name: 'DB Audit',
    icon: DBAuditIcon,
    component: () => import('./DBAudit.Container'),
    requiresPermission: false,
    homeShortcut: true,
    sideBarShortcut: true,
    beforeEnter: async ({ rootStore }) => {
      const { dbAuditStore } = rootStore;
      dbAuditStore.findPanelTotals();
      dbAuditStore.findJobTotals();
      dbAuditStore.findIfcTotals();
    }
  },
  {
    path: '/db-audit/:auditService',
    layout: '/app',
    name: 'DB Audit Service',
    icon: DBAuditIcon,
    component: () => import('./DBAuditService.Container'),
    requiresPermission: false,
    parentRoute: 'DB Audit',
    homeShortcut: true,
    sideBarShortcut: true,
    channels: [
      'panel-revit',
      'panel-plant',
      'panel-yard',
      'panel-field',
      'job',
      'job-plant',
      'ifc-status'
    ],
    beforeEnter: async ({ rootStore, params }) => {
      const { dbAuditStore } = rootStore;
      // DB Audit keeps old findAll method
      if (params?.auditService === 'panel-relational') {
        dbAuditStore.findPanelTotals();
        dbAuditStore.findAll('panel-revit');
        dbAuditStore.findAll('panel-plant');
        dbAuditStore.findAll('panel-yard');
        dbAuditStore.findAll('panel-field');
      }
      if (params?.auditService === 'panel-mark') {
        dbAuditStore.findPanelTotals();
        dbAuditStore.findAll('panel-revit');
        dbAuditStore.findAll('panel-plant');
        dbAuditStore.findAll('panel-yard');
        dbAuditStore.findAll('panel-field');
      }
      if (params?.auditService === 'jobs') {
        dbAuditStore.findJobTotals();
        dbAuditStore.findAll('job');
        dbAuditStore.findAll('job-plant');
      }
      if (params?.auditService === 'ifc') {
        dbAuditStore.findPanelRevitTotals();
        dbAuditStore.findIfcTotals();
        dbAuditStore.findAll('ifc-status');
        dbAuditStore.findAll('panel-revit');
      }
    }
  }
];

export default databaseAuditRoutes;
