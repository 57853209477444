import DevopsIcon from '@mui/icons-material/BackupTable';

const devopsRoutes = [
  {
    path: '/project/:currentProject/devops',
    subRoutes: ['ProjectDashboard'],
    layout: '/app',
    name: 'Devops',
    icon: DevopsIcon,
    component: () => import('./Devops.Container'),
    requiresPermission: false,
    homeShortcut: true,
    sideBarShortcut: true,
    hidden: false,
    projectRoute: true,
    beforeEnter: async ({ rootStore, params }) => {
      const {
        developmentProjectsStore,
        azureDevopsProjectsStore,
        azureDevopsGitStore,
        azureDevopsBuildStore
      } = rootStore;
      if (params?.currentProject)
        developmentProjectsStore
          .find({
            query: { projectName: `@compass/${params.currentProject}` }
          })
          .then(async developmentProjectsStoreFind => {
            const { data } = developmentProjectsStoreFind;
            if (data) {
              const currentProject = data[0];
              console.log(
                'developmentProjectsStore currentProject',
                currentProject
              );
              const foundProject = await azureDevopsProjectsStore.get(
                currentProject.projectDevopsId
              );
              console.log(
                'developmentProjectsStore foundProject',
                foundProject
              );
              const foundGit = await azureDevopsGitStore.get(
                currentProject.projectDevopsId
              );
              console.log('developmentProjectsStore foundGit', foundGit);

              const foundBuildDefs = await azureDevopsBuildStore.find({
                query: { projectId: currentProject.projectDevopsId }
              });
              console.log(
                'developmentProjectsStore foundBuildDefs',
                foundBuildDefs
              );
            }
          });
    }
  }
];

export default devopsRoutes;
