import { databaseDate } from '@compass/utils';
import { makeAutoObservable } from 'mobx';

class ReportViewerStore {
  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  setReportDate = reportDate => {
    return this.rootStore.routerStore.goTo(null, null, { reportDate });
  };

  get reportDate() {
    return (
      this.rootStore.routerStore?.queryParams?.reportDate || databaseDate()
    );
  }

  get entities() {
    return this.rootStore.reportPanelHoldStore.entities
      .slice()
      .filter(entity => entity.reportDate === this.reportDate);
  }
}

export default ReportViewerStore;
