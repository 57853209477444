import {
  NotificationProvider,
  RootStoreProvider,
  ThemeProvider
} from '@compass/react';
import { currentEnvironment, setupUncaughtExceptions } from '@compass/utils';
import React from 'react';
import ReactDOM from 'react-dom';
import appRootStore from 'stores/rootStore.js';
import { pwaRegistration } from 'utils/pwaHelper';
import './index.css';

import App from './App';

let rootStore = appRootStore;
window.rootStore = rootStore;

if (currentEnvironment === 'production') {
  setupUncaughtExceptions(rootStore);
  window.addEventListener('vite:preloadError', event => {
    // window.reload(); // for example, refresh the page
    console.error('vite:preloadError', event);
    if (rootStore.logStore) {
      rootStore.logStore
        .create({
          message: 'Vite Preload Error',
          appName: '@compass/dev-tools',
          level: 'error',
          payload: event
        })
        .catch(err => {
          console.error('Vite Preload Error Log Create Error', err);
        });
    }
  });
}

const turnOnUtils = async () => {
  if (window.utils) {
    window.utils = undefined;
    return `window.utils = undefined`;
  }
  const compassUtils = await import('@compass/utils');
  console.log('compassUtils', compassUtils);
  window.utils = compassUtils;
  return window.utils;
};
const debugMode = storeName => {
  if (rootStore[storeName] && rootStore[storeName].handleDebugMode) {
    rootStore[storeName].handleDebugMode();
    return `${storeName} debugMode set to ${rootStore[storeName].debugMode}`;
  }
  return console.error(`That store didn't have handleDebugMode()`);
};

window.turnOnUtils = turnOnUtils;
window.debugMode = debugMode;

ReactDOM.render(
  <React.StrictMode>
    <RootStoreProvider rootStore={rootStore}>
      <ThemeProvider>
        <NotificationProvider>
          <App />
        </NotificationProvider>
      </ThemeProvider>
    </RootStoreProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

pwaRegistration(rootStore);
