import appVersionManagerStore from './appVersionManagerStore';
import channelViewerStore from './channelViewerStore';
import RPMUploadStore from './cpmUploadStore';
import DevProjectManagementStore from './devProjectManagementStore';
import dockerLogsViewerStore from './dockerLogsViewerStore';
import DockerVersionsStore from './dockerVersionsStore';
import LogsViewerStore from './logViewerStore';
import notificationManagementStore from './notificationManagementStore';
import RevitPluginStore from './revitPluginStore';

const allProjectStores = [
  { name: 'revitPluginStore', store: RevitPluginStore },
  { name: 'channelViewerStore', store: channelViewerStore },
  { name: 'appVersionManagerStore', store: appVersionManagerStore },
  { name: 'logViewerStore', store: LogsViewerStore },
  { name: 'cpmUploadStore', store: RPMUploadStore },

  { name: 'devProjectManagementStore', store: DevProjectManagementStore },
  { name: 'dockerVersionsStore', store: DockerVersionsStore },
  { name: 'dockerLogsViewerStore', store: dockerLogsViewerStore },
  { name: 'notificationManagementStore', store: notificationManagementStore }
];
export default allProjectStores;
