import { RootStore } from '@compass/store';
import {
  capitalizeFirstLetter,
  isSameJobNumber,
  sleep,
  uriToCamel
} from '@compass/utils';
import localForage from 'localforage';
import { makeAutoObservable, reaction, runInAction, when } from 'mobx';
import packageJson from '../../../../package.json';
import serviceStores from '../../serviceStores';

const DEFAULT_SYNC_SERVICE_STATES = {
  loading: false,
  total: 0,
  syncedItems: 0
};
const DEFAULT_PRODUCTION_SERVICE_STATES = {
  total: 0
};

class DatabaseSyncStore {
  syncRootStore = null;
  syncJobNumber = '';
  availableServices = [];
  syncServiceState = DEFAULT_SYNC_SERVICE_STATES;

  panelSyncProgressMessage = 'Ready To Start Sync';
  panelSyncServiceStates = {
    panelRevit: DEFAULT_SYNC_SERVICE_STATES,
    panelPlant: DEFAULT_SYNC_SERVICE_STATES,
    panelYard: DEFAULT_SYNC_SERVICE_STATES,
    panelField: DEFAULT_SYNC_SERVICE_STATES
  };
  panelProductionServiceStates = {
    panelRevit: DEFAULT_PRODUCTION_SERVICE_STATES,
    panelPlant: DEFAULT_PRODUCTION_SERVICE_STATES,
    panelYard: DEFAULT_PRODUCTION_SERVICE_STATES,
    panelField: DEFAULT_PRODUCTION_SERVICE_STATES
  };

  productionServerState = DEFAULT_PRODUCTION_SERVICE_STATES;

  currentSyncUri = '';

  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;

    this.mountDevRootStoreDispose = reaction(
      () => this.enableSyncStore,
      async syncStore => {
        if (!syncStore) {
          if (this.syncRootStore) {
            console.log('Ready to dispose of syncStore');
            this.resetProductionServiceState();
            this.resetSyncServiceState();
            await this.resetSyncRootStore();
          }
        } else {
          if (this.syncRootStore) {
            console.log('there was already a sync store');
            this.resetProductionServiceState();
            this.resetSyncServiceState();
            await this.resetSyncRootStore();
          }
          if (syncStore === 'dev') {
            this.setCurrentSyncUri('https://dev.api.wwprecast.com');
            const mainRootStoreIsDev = rootStore.mode.includes('dev');
            if (mainRootStoreIsDev)
              console.log('RootStore is in DEV mode, setting to itself');
            runInAction(() => {
              this.syncRootStore = mainRootStoreIsDev
                ? new RootStore({
                    appName: `${packageJson.name}-dev-test`,
                    storage: localForage,
                    mode: 'dev',
                    appVersion: packageJson.version,
                    asyncStorage: true,
                    autoUpdate: false,
                    enableNotificationStore: false,
                    enableAuthStore: { debugMode: false },
                    serviceStores: serviceStores,
                    disableViewStore: true
                  })
                : new RootStore({
                    appName: `${packageJson.name}-dev`,
                    storage: localForage,
                    mode: 'dev',
                    appVersion: packageJson.version,
                    asyncStorage: true,
                    autoUpdate: false,
                    enableNotificationStore: false,
                    enableAuthStore: { debugMode: false },
                    serviceStores: serviceStores,
                    disableViewStore: true
                  });

              this.loginSyncRootStore();
            });

            return;
          }
          if (syncStore === 'local') {
            this.setCurrentSyncUri('http://localhost:3030');
            const mainRootStoreIsDev = rootStore.mode.includes('dev');
            if (mainRootStoreIsDev)
              console.log('RootStore is in DEV mode, setting to itself');
            runInAction(() => {
              this.syncRootStore = mainRootStoreIsDev
                ? new RootStore({
                    appName: `${packageJson.name}-local-test`,
                    storage: localForage,
                    mode: 'development',
                    appVersion: packageJson.version,
                    asyncStorage: true,
                    autoUpdate: false,
                    enableNotificationStore: false,
                    enableAuthStore: { debugMode: false },
                    serviceStores: serviceStores,
                    disableViewStore: true,
                    apiOptions: {
                      debugMode: false,
                      authVersion: 2,
                      uriOverride: {
                        development: 'http://localhost:3030'
                      }
                    }
                  })
                : new RootStore({
                    appName: `${packageJson.name}-local`,
                    storage: localForage,
                    mode: 'development',
                    appVersion: packageJson.version,
                    asyncStorage: true,
                    autoUpdate: false,
                    enableNotificationStore: false,
                    enableAuthStore: { debugMode: false },
                    serviceStores: serviceStores,
                    disableViewStore: true,
                    apiOptions: {
                      debugMode: false,
                      authVersion: 2,
                      uriOverride: {
                        development: 'http://localhost:3030'
                      }
                    }
                  });

              this.loginSyncRootStore();
            });

            return;
          }

          if (syncStore === 'stage') {
            this.setCurrentSyncUri('https://stage.api.wwprecast.com');
            console.log('Start stage rootStore');
            runInAction(() => {
              this.syncRootStore = new RootStore({
                appName: `${packageJson.name}-stage`,
                storage: localForage,
                mode: 'stage',
                appVersion: packageJson.version,
                asyncStorage: true,
                autoUpdate: false,
                enableNotificationStore: false,
                enableAuthStore: { debugMode: false },
                serviceStores: serviceStores,
                disableViewStore: true
              });

              this.loginSyncRootStore();
            });

            return;
          }
          console.log('Mode was neither');
        }
      },
      { fireImmediately: true }
    );

    this.getSyncServicesDispose = reaction(
      () => this.syncRootStore,
      async syncStore => {
        if (syncStore && syncStore.servicesStore) {
          const foundServices = await this.syncRootStore.servicesStore.find();
          if (foundServices && foundServices.services) {
            this.setAvailableServices(foundServices.services);
          }
        }
      },
      { fireImmediately: true }
    );

    this.getServiceItemCountDispose = reaction(
      () => this.currentSyncService,
      currentService => {
        if (currentService) {
          console.log('currentService', currentService);
          if (currentService?.serviceName === 'panelRevit') {
            return this.getPanelServiceCounts();
          }
          return this.getServiceItemCount();
        }
        this.resetProductionServiceState();
        return this.resetSyncServiceState();
      }
    );
  }

  setCurrentSyncUri = value => {
    this.currentSyncUri = value;
  };

  setPanelSyncProgressMessage = message => {
    this.panelSyncProgressMessage = message;
  };

  setAvailableServices = services => {
    this.availableServices = services;
  };

  dispose = () => {
    this.mountDevRootStoreDispose && this.mountDevRootStoreDispose();
  };

  setSyncServiceState = (state, value) => {
    this.syncServiceState[state] = value;
  };

  resetSyncServiceState = () => {
    this.syncServiceState = DEFAULT_SYNC_SERVICE_STATES;
  };

  setPanelSyncServiceState = (service, state, value) => {
    console.log('Setting syncService', service, ':', state, 'to - ', value);
    this.panelSyncServiceStates[service][state] = value;
  };

  setPanelProductionServiceState = (service, state, value) => {
    console.log('Setting prodService', service, ':', state, 'to - ', value);
    this.panelProductionServiceStates[service][state] = value;
  };

  setProductionServiceState = (state, value) => {
    this.productionServerState[state] = value;
  };

  resetProductionServiceState = () => {
    this.productionServerState = DEFAULT_PRODUCTION_SERVICE_STATES;
  };

  getPanelServiceCounts = async () => {
    console.log('getPanelServiceCounts');
    if (!this.syncJobNumber) return;
    console.log(
      'getPanelServiceCounts called for syncJobNumber: ',
      this.syncJobNumber
    );
    const query = { $limit: 2000, jobNumber: this.syncJobNumber };
    // Get production panel counts
    const panelRevitJobResults = await this.rootStore.panelRevitStore.find({
      query: query
    });
    if (panelRevitJobResults) {
      this.setPanelProductionServiceState(
        'panelRevit',
        'total',
        panelRevitJobResults.total
      );
    }
    const panelPlantJobResults = await this.rootStore.panelPlantStore.find({
      query: query
    });
    if (panelPlantJobResults) {
      this.setPanelProductionServiceState(
        'panelPlant',
        'total',
        panelPlantJobResults.total
      );
    }
    const panelYardJobResults = await this.rootStore.panelYardStore.find({
      query: query
    });
    if (panelYardJobResults) {
      this.setPanelProductionServiceState(
        'panelYard',
        'total',
        panelYardJobResults.total
      );
    }
    const panelFieldJobResults = await this.rootStore.panelFieldStore.find({
      query: query
    });
    if (panelFieldJobResults) {
      this.setPanelProductionServiceState(
        'panelField',
        'total',
        panelFieldJobResults.total
      );
    }

    // Get local sync panel counts
    const panelRevitJobSyncResults =
      await this.syncRootStore.panelRevitStore.find({
        query: query
      });
    if (panelRevitJobSyncResults) {
      this.setPanelSyncServiceState(
        'panelRevit',
        'total',
        panelRevitJobSyncResults.total
      );
    }
    const panelPlantJobSyncResults =
      await this.syncRootStore.panelPlantStore.find({
        query: query
      });
    if (panelPlantJobSyncResults) {
      this.setPanelSyncServiceState(
        'panelPlant',
        'total',
        panelPlantJobSyncResults.total
      );
    }
    const panelYardJobSyncResults =
      await this.syncRootStore.panelYardStore.find({
        query: query
      });
    if (panelYardJobSyncResults) {
      this.setPanelSyncServiceState(
        'panelYard',
        'total',
        panelYardJobSyncResults.total
      );
    }
    const panelFieldJobSyncResults =
      await this.syncRootStore.panelFieldStore.find({
        query: query
      });
    if (panelFieldJobSyncResults) {
      this.setPanelSyncServiceState(
        'panelField',
        'total',
        panelFieldJobSyncResults.total
      );
    }
  };

  getServiceItemCount = async () => {
    console.log('getServiceItemCount');
    const checkForStore = this.currentSyncService?.storeAvailable;

    if (checkForStore) {
      const currentProductionServiceStore =
        this.rootStore[this.currentSyncService.serviceStore];
      const productionServiceStoreResults =
        await currentProductionServiceStore.find({
          query: { $limit: 0 }
        });
      console.log(
        'productionServiceStoreResults',
        productionServiceStoreResults
      );
      if (
        productionServiceStoreResults &&
        productionServiceStoreResults.total > 0
      ) {
        this.setProductionServiceState(
          'total',
          productionServiceStoreResults.total
        );
      }

      const currentSyncServiceStore =
        this.syncRootStore[this.currentSyncService.serviceStore];
      const syncServiceStoreResults = await currentSyncServiceStore.find({
        query: { $limit: 0 }
      });
      console.log('syncServiceStoreResults', syncServiceStoreResults);
      if (syncServiceStoreResults && syncServiceStoreResults.total > 0) {
        this.setSyncServiceState('total', syncServiceStoreResults.total);
      }
    } else {
      const currentProductionService = this.rootStore.app.service(
        this.currentSyncService.servicePath
      );
      const productionServiceResults = await currentProductionService.find({
        query: { $limit: 0 }
      });
      console.log('productionServiceResults', productionServiceResults);
      if (productionServiceResults && productionServiceResults.total > 0) {
        this.setProductionServiceState('total', productionServiceResults.total);
      }

      const currentService = this.syncRootStore.app.service(
        this.currentSyncService.servicePath
      );
      const serviceResults = await currentService.find({
        query: { $limit: 0 }
      });
      console.log('serviceResults', serviceResults);
      if (serviceResults && serviceResults.total > 0) {
        this.setSyncServiceState('total', serviceResults.total);
      }
    }
  };

  resetSyncRootStore = async () => {
    this.syncRootStore = null;
    this.setAvailableServices([]);
    return Promise.resolve();
  };

  loginSyncRootStore = async () => {
    // if (this.syncRootStore?.authStore?.isSignedIn) {
    //   return console.log('Already Logged In');
    // }

    return await this.syncRootStore?.authStore
      ?.authenticate({
        strategy: 'local',
        email: 'developer@waltersandwolf.com',
        password: 'developer'
      })
      .catch(err => console.warn('Error - loginDev:', err));
  };

  setSyncJobNumber = value => {
    this.syncJobNumber = value;
    if (this.currentSyncService?.serviceName === 'panelRevit') {
      console.log();
      return this.getPanelServiceCounts();
    }
    this.getServiceItemCount();
  };

  syncPanelStoreJob = async () => {
    this.setPanelSyncServiceState('panelRevit', 'loading', true);
    this.setPanelSyncServiceState('panelPlant', 'loading', true);
    this.setPanelSyncServiceState('panelYard', 'loading', true);
    this.setPanelSyncServiceState('panelField', 'loading', true);
    this.setPanelSyncProgressMessage('Starting Sync');
    const { findAllStore } = this.rootStore;
    const panelRevitRequest = await findAllStore.findAllUpdates('panel-revit', {
      jobNumber: this.syncJobNumber
    });
    console.log('panelRevitRequest', panelRevitRequest);
    const panelPlantRequest = await findAllStore.findAllUpdates('panel-plant', {
      jobNumber: this.syncJobNumber
    });
    console.log('panelPlantRequest', panelPlantRequest);
    const panelYardRequest = await findAllStore.findAllUpdates('panel-yard', {
      jobNumber: this.syncJobNumber
    });
    console.log('panelYardRequest', panelYardRequest);
    const panelFieldRequest = await findAllStore.findAllUpdates('panel-field', {
      jobNumber: this.syncJobNumber
    });
    console.log('panelFieldRequest', panelFieldRequest);
    console.log(
      'this.rootStore.findAllStore.requestQueue',
      this.rootStore.findAllStore.requestQueue
    );
    this.setPanelSyncProgressMessage('All 4 Workers Are Spun Up');

    when(
      () =>
        this.rootStore.findAllStore.requestQueue[panelRevitRequest.requestId]
          ?.completed &&
        this.rootStore.findAllStore.requestQueue[panelPlantRequest.requestId]
          ?.completed &&
        this.rootStore.findAllStore.requestQueue[panelYardRequest.requestId]
          ?.completed &&
        this.rootStore.findAllStore.requestQueue[panelFieldRequest.requestId]
          ?.completed,

      async () => {
        this.handlePanelSync();
      }
    );

    return Promise.resolve();
  };

  handlePanelSync = () => {
    console.log(`Ready To Sync All 4 Panel Databases`);
    this.setPanelSyncProgressMessage('Grabbed Panels From Production');

    let revitNewPanels = [];

    this.syncRootStore.panelRevitStore
      .remove(null, {
        query: { syncDatabase: true, jobNumber: this.syncJobNumber }
      })
      .then(async () => {
        this.setPanelSyncProgressMessage('Panel Revit Cleared');
        await sleep(1000);
        return await this.syncRootStore.panelPlantStore.remove(null, {
          query: { syncDatabase: true, jobNumber: this.syncJobNumber }
        });
      })
      .then(async () => {
        this.setPanelSyncProgressMessage('Panel Plant Cleared');
        await sleep(1000);
        return await this.syncRootStore.panelYardStore.remove(null, {
          query: { syncDatabase: true, jobNumber: this.syncJobNumber }
        });
      })
      .then(async () => {
        this.setPanelSyncProgressMessage('Panel Yard Cleared');
        await sleep(1000);
        return await this.syncRootStore.panelFieldStore.remove(null, {
          query: { syncDatabase: true, jobNumber: this.syncJobNumber }
        });
      })
      .then(async () => {
        this.setPanelSyncProgressMessage('Panel Field Cleared');
        await sleep(1000);
        const panelRevitEntities = this.rootStore.panelRevitStore.entities
          .slice()
          .filter(p => isSameJobNumber(p, this.syncJobNumber))
          .filter(p => !p.controlNumber.includes('-X'));

        const panelRevitPanelsWithoutId = panelRevitEntities.slice().map(p => {
          delete p.id; // Make sure to delete id before sending it to be created
          return p;
        });
        console.log('panelRevitPanelsWithoutId ', panelRevitPanelsWithoutId);
        return await this.syncRootStore.panelRevitStore.create(
          panelRevitPanelsWithoutId,
          { query: { syncDatabase: true } }
        ); // this query makes it so the API doesnt create the relational value automatically
      })
      .then(async revitCreated => {
        revitNewPanels = revitCreated;
        this.setPanelSyncProgressMessage(
          `Panel Revit Created ${revitCreated.length} Panels`
        );
        await sleep(1000);

        const panelPlantEntities = this.rootStore.panelPlantStore.entities
          .slice()
          .filter(p => isSameJobNumber(p, this.syncJobNumber))
          .filter(p => !p.controlNumber.includes('-X'));

        const panelPlantPanelsWithoutId = panelPlantEntities.slice().map(p => {
          delete p.id; // Make sure to delete id before sending it to be created
          const foundRevitPanel = revitNewPanels.find(
            rp => rp.controlUUID === p.controlUUID
          );
          p.panelId = foundRevitPanel.id;
          return p;
        });

        console.log(
          'panelPlantPanelsWithoutId length',
          panelPlantPanelsWithoutId.length
        );

        return await this.syncRootStore.panelPlantStore.create(
          panelPlantPanelsWithoutId,
          { query: { syncDatabase: true } }
        );
      })
      .then(async plantCreated => {
        this.setPanelSyncProgressMessage(
          `Panel Plant Created ${plantCreated.length} Panels`
        );
        await sleep(1000);

        const panelYardEntities = this.rootStore.panelYardStore.entities
          .slice()
          .filter(p => isSameJobNumber(p, this.syncJobNumber))
          .filter(p => !p.controlNumber.includes('-X'));

        const panelYardPanelsWithoutId = panelYardEntities.slice().map(p => {
          delete p.id; // Make sure to delete id before sending it to be created
          const foundRevitPanel = revitNewPanels.find(
            rp => rp.controlUUID === p.controlUUID
          );
          p.panelId = foundRevitPanel.id;
          return p;
        });

        console.log(
          'panelYardPanelsWithoutId length',
          panelYardPanelsWithoutId.length
        );

        return await this.syncRootStore.panelYardStore.create(
          panelYardPanelsWithoutId,
          { query: { syncDatabase: true } }
        );
      })
      .then(async yardCreated => {
        this.setPanelSyncProgressMessage(
          `Panel Yard Created ${yardCreated.length} Panels`
        );
        await sleep(1000);

        const panelFieldEntities = this.rootStore.panelFieldStore.entities
          .slice()
          .filter(p => isSameJobNumber(p, this.syncJobNumber))
          .filter(p => !p.controlNumber.includes('-X'));

        const panelFieldPanelsWithoutId = panelFieldEntities.slice().map(p => {
          delete p.id; // Make sure to delete id before sending it to be created
          const foundRevitPanel = revitNewPanels.find(
            rp => rp.controlUUID === p.controlUUID
          );
          p.panelId = foundRevitPanel.id;
          return p;
        });

        console.log(
          'panelFieldPanelsWithoutId length',
          panelFieldPanelsWithoutId.length
        );

        return await this.syncRootStore.panelFieldStore.create(
          panelFieldPanelsWithoutId,
          { query: { syncDatabase: true } }
        );
      })
      .then(async fieldCreated => {
        this.setPanelSyncProgressMessage(
          `Panel Field Created ${fieldCreated.length} Panels`
        );
        this.getPanelServiceCounts();

        return Promise.resolve();
      })
      .then(async () => {
        this.setPanelSyncProgressMessage('Refreshing Data');
        runInAction(() => {
          this.setPanelSyncServiceState('panelRevit', 'loading', false);
          this.setPanelSyncServiceState('panelPlant', 'loading', false);
          this.setPanelSyncServiceState('panelYard', 'loading', false);
          this.setPanelSyncServiceState('panelField', 'loading', false);
        });
        this.setPanelSyncProgressMessage('Sync Completed');
        return Promise.resolve();
      })
      .catch(err => {
        console.warn('Something went wrong', err);

        this.setPanelSyncProgressMessage('Error Occurred While Trying To Sync');
      });
  };

  createSyncWorker = servicePath => {
    const { token } = this.rootStore.authStore;
    const workerResult = new Worker(
      new URL('./syncFromProductionWorker.js', import.meta.url),
      { type: 'module' }
    );
    if (workerResult) {
      this.addWorkerListener(workerResult);
      workerResult.postMessage({
        auth: {
          token: token
        },
        request: { service: servicePath },
        apiUri: this.currentSyncUri
      });
      return workerResult;
    }
    return 'Failed';
  };

  addWorkerListener = currentWorker => {
    if (!currentWorker) throw Error('No worker');
    currentWorker.onmessage = e => {
      console.log('Main received a message back from worker');
      console.log('Message:', e);
      if (e.data) {
        return this.setWorkerResponse(JSON.parse(e.data));
      }
      console.log('Response did not have data', e);
    };
    currentWorker.onerror = e => {
      if (this.debugMode)
        console.warn('Main received an error back from worker');
      console.warn('Error:', e);
      if (e.data) {
        return this.setWorkerError(e.data);
      }
    };
    currentWorker.onmessageerror = e => {
      console.warn('Main received an message error back from worker');
      console.warn('Error:', e);
      if (e.data) {
        return this.setWorkerError(e.data);
      }
    };
  };
  setWorkerResponse = async workerResponse => {
    console.log('workerResponse', workerResponse);
    this.getServiceItemCount();
  };

  sync = async () => {
    if (!this.currentSyncService) throw new Error('Requires a service to sync');
    this.setSyncServiceState('loading', true);
    // const checkForStore =
    //   this.syncRootStore[this.currentSyncService.serviceStore];
    // const checkProductionForStore =
    //   this.rootStore[this.currentSyncService.serviceStore];
    console.log('SYNC currentSyncService', this.currentSyncService);
    const { storeAvailable, servicePath } = this.currentSyncService;
    if (storeAvailable) {
      const currentRequest =
        await this.rootStore.findAllStore.findAllUpdates(servicePath);
      console.log('SYNC currentRequest', currentRequest);
      console.log(
        'SYNC requestQueue currentRequest',
        this.rootStore.findAllStore.requestQueue[currentRequest.requestId]
      );
      if (Array.isArray(currentRequest)) {
        when(
          () =>
            this.rootStore.findAllStore.requestQueue &&
            currentRequest.every(
              currentArrayRequest =>
                this.rootStore.findAllStore.requestQueue[
                  currentArrayRequest.requestId
                ].completed
            ),

          () => {
            console.log('READY TO SYNC ARRAY');
            this.createSyncWorker(servicePath);
          }
        );
      } else {
        when(
          () =>
            this.rootStore.findAllStore.requestQueue &&
            this.rootStore.findAllStore.requestQueue[
              currentRequest.requestId
            ] &&
            this.rootStore.findAllStore.requestQueue[currentRequest.requestId]
              .completed,
          () => {
            console.log('READY TO SYNC');
            console.log(
              ' this.rootStore.findAllStore.requestQueue[currentRequest.requestId]',
              this.rootStore.findAllStore.requestQueue[currentRequest.requestId]
            );
            this.createSyncWorker(servicePath);
          }
        );
      }
    }
    //   const store = this.rootStore[serviceStore];
    //   const { find: findProductionItems } = store;
    //   console.log('SYNC using store');
    //   const result = await findProductionItems({
    //     query: { $limit: 0 }
    //   }).catch(err => console.warn('Something went wrong', err));
    //   if (result && result.total && result.total > 0) {
    //     const { total } = result;

    //     const divider = Math.ceil(total / 1000);
    //     const looper = Array.from(Array(divider).keys());

    //     let allItems = [];

    //     let jobNumberQuery = {};
    //     if (this.syncJobNumber) {
    //       jobNumberQuery = {
    //         jobNumber: this.syncJobNumber
    //       };
    //     }

    //     for (const loopNumber of looper) {
    //       const foundLoopItems = await findProductionItems({
    //         query: {
    //           $limit: 1000,
    //           $skip: 1000 * loopNumber,
    //           ...jobNumberQuery
    //         }
    //       }).catch(err => console.warn('Something went wrong', err));
    //       allItems = [...allItems, ...foundLoopItems.data];
    //     }
    //     console.log('allItems', allItems);
    //     if (allItems.length) {
    //       let removedIDs = allItems.map(p => {
    //         if ('id' in p) delete p.id;
    //         return p;
    //       });
    //       console.log('removedIDs', removedIDs);

    //       const currentSyncStore = this.syncRootStore[serviceStore];

    //       await currentSyncStore
    //         .remove(null, { query: { syncDatabase: true } })
    //         .then(async () => {
    //           return await currentSyncStore
    //             .create(removedIDs)
    //             .catch(err => console.warn('Something went wrong', err));
    //         })
    //         .then(async () => {
    //           runInAction(() => {
    //             this.rootStore.notificationStore.addNotification({
    //               message: `Successfully synced ${removedIDs.length} items`,
    //               options: { variant: 'success', autoHideDuration: 5000 }
    //             });
    //             this.setSyncServiceState('loading', false);
    //             return Promise.resolve();
    //           });
    //         })
    //         .catch(err => console.warn('Something went wrong', err));
    //     }
    //   }
    // } else {
    //   console.log('SYNC using service');
    //   this.rootStore.notificationStore?.addNotification({
    //     message: `Only able to store-to-store sync right now`,
    //     options: { variant: 'warning', autoHideDuration: 5000 }
    //   });
    this.setSyncServiceState('loading', false);
    // }
    // return Promise.resolve();
  };

  get enableSyncStore() {
    const { routerStore } = this.rootStore;
    if (routerStore.currentRoute?.key.includes('DBSync')) {
      if (routerStore.params?.server.includes('local-dev')) return 'local';
      if (routerStore.params?.server.includes('cloud-dev')) return 'dev';
      if (routerStore.params?.server.includes('stage')) return 'stage';
    }
    return null;
  }

  get syncServices() {
    if (!this.availableServices.length) return [];
    return this.availableServices
      .slice()
      .filter(servicePath => !servicePath.includes('migrate/'))
      .filter(servicePath => !servicePath.includes('panel-plant'))
      .filter(servicePath => !servicePath.includes('panel-field'))
      .filter(servicePath => !servicePath.includes('panel-yard'))
      .filter(servicePath => servicePath !== 'panel')
      .sort()
      .map(servicePath => {
        const convertV2 = servicePath.replace('v2/', '');
        const serviceName = uriToCamel(convertV2);
        const storeName = `${serviceName}Store`;
        if (serviceName === 'panel') {
          console.log('PANLESERVIVE');
          console.log('servicePath', servicePath);
          console.log('serviceName', serviceName);
          console.log('serviceTitle', capitalizeFirstLetter(serviceName));
          console.log('serviceStore', storeName);
          console.log(
            'syncReady',
            !!this.rootStore?.app?.service(servicePath) &&
              !!this.syncRootStore?.app?.service(servicePath)
          );
          console.log(
            'storeAvailable',
            !!this.rootStore[storeName] && !!this.syncRootStore[storeName]
          );
          return {
            servicePath,
            serviceName,
            serviceTitle: capitalizeFirstLetter(serviceName),
            serviceStore: storeName,
            syncReady:
              !!this.rootStore?.app?.service(servicePath) &&
              !!this.syncRootStore?.app?.service(servicePath),
            storeAvailable:
              !!this.rootStore[storeName] && !!this.syncRootStore[storeName]
          };
        }
        return {
          servicePath,
          serviceName,
          serviceTitle: capitalizeFirstLetter(serviceName),
          serviceStore: storeName,
          syncReady:
            !!this.rootStore?.app?.service(servicePath) &&
            !!this.syncRootStore?.app?.service(servicePath),
          storeAvailable:
            !!this.rootStore[storeName] && !!this.syncRootStore[storeName]
        };
      });
  }

  get currentSyncService() {
    if (!this.syncServices.length) return null;
    const {
      routerStore: { queryParams }
    } = this.rootStore;
    return this.syncServices.find(
      service => service.servicePath === queryParams?.service
    );
  }

  get availableJobNumbers() {
    return this.rootStore.jobStore.entities
      .slice()
      .map(j => j.jobNumber)
      .sort();
  }
}

export default DatabaseSyncStore;
