import { makeAutoObservable } from 'mobx';

class NotificationManagementStore {
  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  get pushConnections() {
    const {
      userStore,
      pushDeviceConnectionStore: { entities: deviceConnectionEntities }
    } = this.rootStore;
    return deviceConnectionEntities.map(connection => {
      const foundUser = userStore.entities.find(
        u => u.id === connection.userId
      );
      let pushType = 'Unknown';
      if (connection.endpoint.includes('fcm.googleapis.com')) {
        pushType = 'Chrome';
      }
      if (connection.endpoint.includes('web.push.apple.com')) {
        pushType = 'Safari';
      }
      return {
        ...connection,
        pushType: pushType,
        email: foundUser?.email ? foundUser.email : 'unknown'
      };
    });
  }
  get userSubscriptionsWithEmail() {
    const {
      userStore,
      userSubscriptionStore: { entities: userSubscriptionEntities }
    } = this.rootStore;
    return userSubscriptionEntities.map(connection => {
      const foundUser = userStore.entities.find(
        u => u.id === connection.userId
      );

      return {
        ...connection,
        email: foundUser?.email ? foundUser.email : 'unknown'
      };
    });
  }

  get currentTab() {
    return this.rootStore.routerStore?.queryParams?.tabName;
  }
}

export default NotificationManagementStore;
