import { sortDateAsc, uniqueArray, versionCheck } from '@compass/utils';
import { makeAutoObservable } from 'mobx';

class AppVersionManagerStore {
  onlyLatest = false;
  showRevitPlugins = true;
  createAppVersionDialog = false;
  updateLevel = '';
  filter = '';

  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
    this.setUpdateLevel('Low');
  }

  setFilter = value => {
    this.filter = value;
  };

  setOnlyLatest = () => {
    this.onlyLatest = !this.onlyLatest;
  };

  setShowRevitPlugins = () => {
    this.showRevitPlugins = !this.showRevitPlugins;
  };

  setUpdateLevel = value => {
    this.updateLevel = value;
  };

  get appVersions() {
    const { devProjectManagementStore } = this.rootStore;
    if (this.onlyLatest && this.rootStore.appVersionStore.entities.length > 0) {
      let allAppVersions = this.rootStore.appVersionStore.entities
        .slice()
        .sort((a, b) => sortDateAsc(a.releaseDate, b.releaseDate));
      if (devProjectManagementStore?.currentDevProject?.projectName) {
        allAppVersions = allAppVersions.filter(i =>
          i.appName.includes(
            devProjectManagementStore?.currentDevProject.projectName
          )
        );
      }
      const eachAppLatest = allAppVersions.reduce((acc, cur) => {
        const foundAppIndex = acc.findIndex(
          appVer => appVer.appName === cur.appName
        );
        if (foundAppIndex === -1) {
          acc.push(cur);
        } else {
          if (acc[foundAppIndex].version && cur.version) {
            const checkedVersion = versionCheck(
              acc[foundAppIndex].version,
              cur.version
            );

            if (!checkedVersion) {
              acc[foundAppIndex] = cur;
            }
          }
        }
        return acc;
      }, []);
      return eachAppLatest.filter(plugin => {
        if (!this.showRevitPlugins) {
          return !plugin.appName.includes('plugins');
        }
        return true;
      });
    }
    let includesPlugins = this.rootStore.appVersionStore.entities
      .slice()
      .filter(plugin => {
        if (!this.showRevitPlugins) {
          return !plugin.appName.includes('plugins');
        }
        return true;
      })
      .sort((a, b) => sortDateAsc(a.releaseDate, b.releaseDate));
    if (devProjectManagementStore?.currentDevProject?.projectName) {
      includesPlugins = includesPlugins.filter(i =>
        i.appName.includes(
          devProjectManagementStore?.currentDevProject.projectName
        )
      );
    }
    return includesPlugins;
  }

  get allAppNames() {
    return uniqueArray(
      this.rootStore.appVersionStore.entities.slice().map(i => i.appName)
    );
  }
}

export default AppVersionManagerStore;
