import { groupBy, isSameId, uniqueArray } from '@compass/utils';
import { makeAutoObservable } from 'mobx';

class UpdateHistoryViewStore {
  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  get currentUpdateHistoryEntities() {
    return this.rootStore.updateHistoryStore.entities
      .slice()
      .filter(history => {
        return (
          history.createdDate ===
          this.rootStore.routerStore?.queryParams?.createdDate
        );
      });
  }

  get entitiesToJson() {
    if (!this.currentUpdateHistoryEntities?.length) return [];
    return this.currentUpdateHistoryEntities.slice().map(historyData => {
      return {
        ...historyData,
        oldValues: JSON.parse(historyData.oldValues),
        newValues: JSON.parse(historyData.newValues)
      };
    });
  }

  get groupDataByUser() {
    if (!this.entitiesToJson?.length) return [];

    const groupByUser = groupBy(this.entitiesToJson.slice(), 'userId');

    const formattedData = Object.entries(groupByUser).map(([key, value]) => {
      const updateValues = groupByUser[key];
      const data = {
        userId: parseInt(key),
        data: updateValues
      };
      return data;
    });
    return formattedData;
  }

  get currentHistoryUserIds() {
    return uniqueArray(this.groupDataByUser.map(u => u.userId));
  }

  get currentDialogHistory() {
    const { routerStore } = this.rootStore;
    return this.groupDataByUser?.slice().filter(userData => {
      return isSameId(userData.userId, routerStore?.queryParams?.dialogContext);
    });
  }
}

export default UpdateHistoryViewStore;
