import HubUploadIcon from '@mui/icons-material/Publish';

const dockerLogsRoutes = [
  {
    path: '/project/api/service-stats',
    layout: '/app',
    name: 'Service Stats',
    projectRoute: true,
    icon: HubUploadIcon,
    component: () => import('./ServiceStats.Container'),
    requiresPermission: false,
    channels: ['service-stats'],
    enableAutoFind: true,
    beforeEnter: async ({ rootStore }) => {
      // rootStore.dockerLogsStore.find();
    }
  }
];

export default dockerLogsRoutes;
