import { makeAutoObservable } from 'mobx';

class DevProjectManagementStore {
  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  get allPageRoutes() {
    const { routerStore } = this.rootStore;
    const allRoutesNames = Object.keys(routerStore.routes);
    const allPageRoutes = allRoutesNames
      .filter(routeName => {
        if (routeName === 'Dashboard') return false; // Added at top of list after sort
        const currentRoute = routerStore.routes[routeName];
        if (currentRoute && currentRoute.path.includes('/project/'))
          return false;
        if (currentRoute && currentRoute.path.includes('/auth/')) return false;
        if (currentRoute && currentRoute.forwardRoute) return false;
        if (currentRoute && currentRoute.hidden) return false;
        if (
          currentRoute &&
          currentRoute.subRoutes &&
          currentRoute.subRoutes.length
        )
          return false;
        return true;
      })
      .map(routeName => {
        const currentRoute = routerStore.routes[routeName];
        return currentRoute?.title ? currentRoute.title : routeName;
      })
      .sort();
    return ['Dashboard', ...allPageRoutes].map(routeName => {
      return { title: routeName };
    });
  }

  get availableProjectTools() {
    const { routerStore } = this.rootStore;
    const allRoutesNames = Object.keys(routerStore.routes);
    const currentProjectRoutes = allRoutesNames
      .filter(routeName => {
        if (routeName === 'ProjectDashboard') return false; // This list is to show on ProjectDashboard
        const currentRoute = routerStore.routes[routeName];
        if (currentRoute && currentRoute.path.includes('/project/:'))
          return true;
        if (
          currentRoute &&
          currentRoute.title === 'CPM Plugins' &&
          this.currentDevProject?.projectName === '@compass/revit-plugins'
        )
          return true;
        if (
          currentRoute &&
          currentRoute.title === 'CPM Upload' &&
          this.currentDevProject?.projectName === '@compass/plugin-manager'
        )
          return true;
        // if (currentRoute && currentRoute.path.includes('/auth/')) return false;
        // if (currentRoute && currentRoute.forwardRoute) return false;
        return false;
      })
      .map(routeName => {
        const currentRoute = routerStore.routes[routeName];
        return currentRoute?.title ? currentRoute.title : routeName;
      })
      .sort();
    return currentProjectRoutes;
  }

  get allDevelopmentProjects() {
    const { developmentProjectsStore } = this.rootStore;
    if (!developmentProjectsStore?.entities?.length > 0) return [];
    return developmentProjectsStore.entities.slice().sort((a, b) => {
      const nameA = a.projectTitle.toUpperCase(); // ignore upper and lowercase
      const nameB = b.projectTitle.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  get currentDevProject() {
    const { routerStore } = this.rootStore;
    if (!this.allDevelopmentProjects?.length > 0) return [];
    return this.allDevelopmentProjects.find(
      project =>
        project?.projectName?.replace('@compass/', '') ===
        routerStore?.params?.currentProject
    );
  }

  get isProjectSelection() {
    const {
      routerStore: { currentPath }
    } = this.rootStore;
    return (
      currentPath &&
      (currentPath.includes('/project') || currentPath.includes('/job'))
    );
  }
}

export default DevProjectManagementStore;
