import StoreVisualizerIcon from '@mui/icons-material/AccountTree';

const storeVisualizerRoutes = [
  {
    path: '/store-visualizer/:store',
    layout: '/app',
    name: 'Store Visualizer',
    defaultParams: { store: 'absorption' },
    icon: StoreVisualizerIcon,
    component: () => import('./StoreVisualizer.Container'),
    requiresPermission: false,
    homeShortcut: true,
    sideBarShortcut: true,
    channels: [':store']
  }
];

export default storeVisualizerRoutes;
