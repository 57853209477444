import ProjectDashboardIcon from '@mui/icons-material/SpaceDashboard';

const projectDashboardRoutes = [
  {
    path: '/project/:currentProject/dashboard',
    layout: '/app',
    name: 'Project Dashboard',
    icon: ProjectDashboardIcon,
    component: () => import('./ProjectDashboard.Container'),
    requiresPermission: false,
    homeShortcut: true,
    sideBarShortcut: true,
    hidden: false,
    projectRoute: true
  }
];

export default projectDashboardRoutes;
