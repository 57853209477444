import { registerSW } from 'virtual:pwa-register';

export function permissionRequest() {
  return new Promise(function (resolve, reject) {
    const permissionResult = Notification.requestPermission(function (result) {
      resolve(result);
    });

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  })
    .then(function (permissionResult) {
      console.log('permissionResult', permissionResult);
      if (permissionResult !== 'granted') {
        console.error("We weren't granted permission.");
      }
    })
    .catch(err => {
      console.error('Something went wrong with permissionRequest.', err);
    });
}

export const pwaRegistration = rootStore => {
  try {
    const updateSW = registerSW({
      onNeedRefresh() {
        console.log('onNeedRefresh');
        console.log('onNeedRefresh updateSW', updateSW);
        if (rootStore?.viewStore) {
          rootStore.viewStore?.setAppUpdateFound(true);
        }
      },
      onOfflineReady() {
        console.log('onOfflineReady');
        console.log('onOfflineReady updateSW', updateSW);
      },
      onRegisteredSW(swPath, registration) {
        console.log('onRegisteredSW swPath', swPath);
        if (rootStore?.viewStore) {
          rootStore.viewStore?.setSwRegistration(registration);
        }
      },
      onRegisterError(err) {
        console.warn('Service Worker Error', err);
        if (rootStore?.logStore) {
          rootStore.logStore
            .create({
              message: 'Error Creating Service Worker',
              level: 'error',
              payload: err
            })
            .catch(err => {
              console.error('Error', err);
            });
        }
      }
    });
    if (rootStore?.viewStore && updateSW) {
      rootStore.viewStore.setSWUpdater(updateSW);
    }
    return 'Successfully Registered';
  } catch (err) {
    console.warn(
      'Something went wrong trying to register a serviceWorker',
      err
    );
  }
};
