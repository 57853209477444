//@ts-check
import { RootStore } from '@compass/store';
import { camelToTitle, isSameId } from '@compass/utils';
import localForage from 'localforage';
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import serviceStores from 'stores/serviceStores';

class EntityViewerStore {
  loading = false;
  isReady = false;
  availableServices = [];

  sortState = {};
  filterState = {};

  currentServiceItems = {};

  currentServer = 'production';
  currentApp;

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;

    this.currentApp = rootStore;

    reaction(
      () => this.currentHeaders,
      currentHeaders => {
        if (currentHeaders) {
          // console.log('reaction to params', readableJS(currentHeaders));

          this.rootStore.dataGridStore.namespaces.entityViewerBody.setColumnHeaders(
            currentHeaders.map(headerKey => {
              let type = 'string';
              if (
                headerKey.includes('Date') ||
                headerKey === 'removed' ||
                headerKey === 'rearViewIFC'
              ) {
                if (!headerKey.includes('EditedBy')) {
                  type = 'date';
                }
              }
              if (headerKey.includes('Time')) {
                type = 'time';
              }
              if (headerKey === 'id' || headerKey === 'jobNumber') {
                type = 'number';
              }
              if (
                headerKey.includes('Allowed') ||
                headerKey.includes('Archived') ||
                headerKey.includes('archived') ||
                headerKey.includes('Modeled') ||
                headerKey.includes('Drawn') ||
                headerKey.includes('Checked') ||
                headerKey === 'required' ||
                headerKey === 'adminUser' ||
                headerKey === 'isValidated'
              ) {
                type = 'boolean';
              }
              return {
                disableEdit:
                  headerKey === 'id' || this.selectedService === 'channel',
                key: headerKey,
                title: camelToTitle(headerKey),
                dataType: type
              };
            })
          );
        }
      },
      { delay: 200 }
    );
  }

  setServer = server => {
    if (server === this.currentServer) return;
    if (server === 'cloud-dev') {
      this.currentServer = server;
      this.currentServiceItems = {};
      this.availableServices = [];
      this.currentApp = undefined;
      console.log('setting currentServer:', server);
      this.currentApp = new RootStore({
        appName: `${this.rootStore.appName}-cloud-dev-entity-viewer`,
        storage: localForage,
        mode: 'dev',
        appVersion: this.rootStore.appVersion,
        asyncStorage: true,
        autoUpdate: false,
        enableNotificationStore: false,
        enableAuthStore: { debugMode: false },
        serviceStores: serviceStores,
        disableViewStore: true
      });
      console.log('setting this.currentApp:', this.currentApp);
      return this.findServiceStores();
    }
    if (server === 'local-dev') {
      this.currentServer = server;
      this.currentServiceItems = {};
      this.availableServices = [];
      this.currentApp = undefined;
      console.log('setting currentServer:', server);
      this.currentApp = new RootStore({
        appName: `${this.rootStore.appName}-local-dev-entity-viewer`,
        storage: localForage,
        mode: 'development',
        appVersion: this.rootStore.appVersion,
        asyncStorage: true,
        autoUpdate: false,
        enableNotificationStore: false,
        enableAuthStore: { debugMode: false },
        serviceStores: serviceStores,
        disableViewStore: true,
        apiOptions: {
          authVersion: 2,
          uriOverride: {
            development: 'http://localhost:3030'
          }
        }
      });
      console.log('setting this.currentApp:', this.currentApp);
      return this.findServiceStores();
    }
    this.currentServer = 'production';
    this.currentServiceItems = {};
    this.availableServices = [];
    this.currentApp = undefined;
    this.currentApp = this.rootStore;
    return this.findServiceStores();
  };

  setupChannelListeners = servicePath => {
    this.currentApp.app.service(servicePath).on('created', created => {
      if (this.currentServiceItems[this.selectedService]?.data) {
        this.currentServiceItems[this.selectedService].data.push(created);
      }
    });
    this.currentApp.app.service(servicePath).on('patched', patched => {
      if (this.currentServiceItems[this.selectedService]?.data) {
        const foundIndex = this.currentServiceItems[
          this.selectedService
        ].data.findIndex(item => isSameId(item.id, patched.id));
        if (foundIndex > -1) {
          this.currentServiceItems[this.selectedService].data[foundIndex] =
            patched;
        }
      }
    });
    this.currentApp.app.service(servicePath).on('removed', removed => {
      if (this.currentServiceItems[this.selectedService]?.data) {
        const newArray = this.currentServiceItems[
          this.selectedService
        ].data.filter(item => !isSameId(item.id, removed.id));
        this.currentServiceItems[this.selectedService].data.replace(newArray);
      }
    });
  };

  setSelectedService = (servicePath, needsRouting = true) => {
    this.loading = true;
    if (!this.currentApp.app.service(servicePath)) {
      throw new Error('That service is not available', servicePath);
    }
    if (needsRouting)
      this.rootStore.routerStore.goTo(null, { servicePath: servicePath });
    runInAction(() => {
      this.sortState = {};
      this.filterState = {};
    });
    // this.selectedService = servicePath;
    this.findServiceItems().then(() => {
      this.setupChannelListeners(servicePath);
      runInAction(() => {
        this.loading = false;
      });
    });
    // Need to add event handlers for created/patched/removed
    return servicePath;
  };

  findServiceStores = async () => {
    const foundServices = await this.currentApp.servicesStore
      .find()
      .catch(err => {
        console.log('something went wrong finding services', err);
      });
    if (foundServices?.serviceIDs?.length > 0) {
      runInAction(() => {
        this.availableServices = foundServices?.serviceIDs
          .slice()
          .filter(i => {
            if (i.name.includes('autodesk')) return false;
            if (i.name.includes('azure')) return false;
            if (i.name.includes('sage')) return false;
            if (i.name.includes('auth')) return false;
            return true;
          })
          .sort((a, b) => (a.name < b.name ? -1 : 1));
        if (!this.selectedService) {
          this.setSelectedService(this.availableServices[0].name);
        }
        if (
          this.selectedService &&
          !this.currentServiceItems[this.selectedService]
        ) {
          this.setSelectedService(this.selectedService, false);
        }
      });
    }
  };

  findServiceItems = async () => {
    const foundTotals = await this.currentApp.app
      .service(this.selectedService)
      .find({ query: { $limit: 0 } })
      .catch(err => {
        console.log('something went wrong finding services', err);
      });
    if (!foundTotals || foundTotals?.total === 0) {
      runInAction(() => {
        this.currentServiceItems[this.selectedService] = {
          total: 0,
          data: []
        };
      });
      const emptyReturnNotificationMessage = `Found ${foundTotals.total} items for ${this.selectedService}`;
      return this.rootStore.notificationStore.addNotification({
        message: emptyReturnNotificationMessage,
        options: { variant: 'info' }
      });
    }
    if (foundTotals?.total > 20000) {
      runInAction(() => {
        this.currentServiceItems[this.selectedService] = {
          total: foundTotals?.total,
          data: []
        };
      });
      return this.rootStore.notificationStore.addNotification({
        message: `Found ${foundTotals.total} items for ${this.selectedService}, too many to try to fetch, 20k is current max`,
        options: { variant: 'warning' }
      });
    }
    this.rootStore.notificationStore.addNotification({
      message: `Found ${foundTotals.total} items for ${this.selectedService}, fetching all`,
      options: { variant: 'info' }
    });
    const loopCount = Math.ceil(foundTotals?.total / 1000);
    const looper = Array.from(Array(loopCount).keys());

    runInAction(() => {
      this.currentServiceItems[this.selectedService] = {
        total: foundTotals?.total,
        data: []
      };
    });
    Promise.all(
      looper.map(async runNumber => {
        const foundItems = await this.currentApp.app
          .service(this.selectedService)
          .find({
            query: {
              $limit: 1000,
              $skip: 1000 * runNumber
            }
          })
          .catch(err => {
            console.log('something went wrong finding services', err);
          });
        if (foundItems?.data?.length > 0) {
          return foundItems.data;
        }
      })
    ).then(returnedFromPromises => {
      const flattened = returnedFromPromises.flat();
      runInAction(() => {
        this.currentServiceItems[this.selectedService].data = flattened;
        this.sortState = {
          sortBy: [],
          sortDirection: {}
        };
      });
    });
  };

  setFilter = (columnKey, filterValue) => {
    if (filterValue === '' && this.filterState?.[columnKey]) {
      delete this.filterState[columnKey];
      return;
    }
    this.filterState[columnKey] = filterValue;
  };

  handleMultiSort = ({ sortBy, sortDirection }) => {
    if (this.sortState.sortBy.includes(sortBy)) {
      runInAction(() => {
        this.sortState.sortDirection[sortBy] = sortDirection;
      });
      return this.sortState;
    }
    if (this.sortState?.sortBy?.length === 4) {
      return this.rootStore.notificationStore.addNotification({
        message: 'You can only sort up to 4 columns',
        options: { variant: 'info' }
      });
    }
    runInAction(() => {
      this.sortState.sortBy.push(sortBy);
      this.sortState.sortDirection[sortBy] = sortDirection;
    });
    return this.sortState;
  };

  removeSortBy = sortBy => {
    runInAction(() => {
      this.sortState.sortBy = this.sortState.sortBy.filter(
        sorted => !sorted.includes(sortBy)
      );
      delete this.sortState.sortDirection[sortBy];
    });
  };

  get selectedService() {
    const { routerStore } = this.rootStore;
    return routerStore?.params?.servicePath || '';
  }

  get availableServiceNames() {
    return this.availableServices?.slice().map(i => i.name);
  }

  get entities() {
    return this.currentServiceItems[this.selectedService]?.data?.slice() || [];
  }

  get currentHeaders() {
    if (
      !this.currentServiceItems[this.selectedService]?.data ||
      !this.currentServiceItems[this.selectedService]?.data[0]
    )
      return ['id'];
    const firstEntityKeys = Object.keys(
      this.currentServiceItems[this.selectedService]?.data[0]
    );
    if (firstEntityKeys.includes('id')) {
      const currentKeys = firstEntityKeys
        .slice()
        .filter(i => i !== 'id')
        .sort((a, b) => (a < b ? -1 : 1));
      return ['id', ...currentKeys];
    }
    if (firstEntityKeys.includes('connectionId')) {
      const currentKeys = firstEntityKeys
        .slice()
        .filter(i => i !== 'connectionId')
        .sort((a, b) => (a < b ? -1 : 1));
      return ['connectionId', ...currentKeys];
    }
    return [...firstEntityKeys];
  }

  get dataGridStoreCurrentData() {
    if (
      !this.rootStore?.dataGridStore?.namespaces?.entityViewerBody?.currentData
    ) {
      return [];
    }
    const currentData =
      this.rootStore?.dataGridStore?.namespaces?.entityViewerBody?.currentData;
    return currentData;
  }

  get dataGridStoreCurrentDataLength() {
    // return this.entities.length
    return this.dataGridStoreCurrentData.length;
  }
}

export default EntityViewerStore;
