const settingsRoutes = [
  {
    path: '/settings',
    layout: '/app',
    name: 'Settings',
    icon: null,
    component: () => import('./Settings.Container'),
    requiresPermission: false,
    hidden: true,
    requiresAuth: true,
    channels: ['user-subscription'],
    beforeEnter: async ({ rootStore }) => {},
    onEnter: async ({ rootStore }) => {},
    beforeExit: async ({ rootStore }) => {},
    onExit: async ({ rootStore }) => {}
  }
];
export default settingsRoutes;
