import DBSyncIcon from '@mui/icons-material/SyncAlt';

const databaseSyncRoutes = [
  {
    path: '/db-sync/:server/list',
    layout: '/app',
    name: 'DB Sync',
    defaultParams: { server: 'cloud-dev' },
    icon: DBSyncIcon,
    component: () => import('./DBSync.Container'),
    requiresPermission: false,
    homeShortcut: true,
    sideBarShortcut: true
  },
  {
    path: '/db-sync/:server/sync',
    layout: '/app',
    name: 'DB Sync Service',
    defaultParams: { server: 'dev' },
    defaultQueryParams: { service: 'user' },
    icon: DBSyncIcon,
    component: () => import('./DBSyncService/DBSyncService.Container'),
    requiresPermission: false,
    parentRoute: 'DBSync',
    homeShortcut: false,
    hidden: true,
    subRoutes: ['DBSync'],
    sideBarShortcut: true,
    beforeEnter: async ({ rootStore }) => {
      const { jobStore } = rootStore;
      jobStore.find();
    }
  }
];

export default databaseSyncRoutes;
