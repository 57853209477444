//@ts-check
import {
  ErrorBoundary,
  LayoutRouter,
  NotificationButtonAppUpdate,
  NotificationButtonInstallPwa
} from '@compass/react';
import { computed, makeObservable, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { lazy } from 'react';

const LazyAuth = lazy(() =>
  import('@compass/react').then(module => ({
    default: module.AuthLayout
  }))
);

const LazyApp = lazy(() =>
  import('@compass/react').then(module => ({
    default: module.AppLayout
  }))
);

const layouts = [
  {
    path: '/app',
    component: LazyApp
  },
  { path: '/auth', component: LazyAuth }
];

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    makeObservable(this, {
      watchStandardNotifications: computed
    });
    this.disposeStandardNotification = reaction(
      () => this.watchStandardNotifications,
      standardNotifications => {
        standardNotifications.slice().map(standardNotification => {
          // console.log('standardNotification', toJS(standardNotification));
          return this.handleStandardNotifications(standardNotification);
        });
      },
      { fireImmediately: true }
    );
  }

  componentWillUnmount() {
    this.disposeStandardNotification && this.disposeStandardNotification();
  }

  handleStandardNotifications = notification => {
    // console.log('handleStandardNotifications', toJS(notification));
    const { displayed, addNotification, removeStandardNotification } =
      this.props.notificationStore;
    // Do nothing if snackbar is already displayed
    if (displayed.find(n => n.key === notification.key)) {
      // console.log('already displayed');
      return;
    }
    // Display snackbar using notistack

    if (notification.standardNotificationName === 'Test') {
      // console.log('Test', toJS(notification));
      const message = 'Test';
      const options = { autoHideDuration: 3500 };
      // console.log('message', toJS(message));
      // console.log('options', toJS(options));
      addNotification({
        message: message,
        options: options
      });

      return removeStandardNotification(notification.standardNotificationName);
    }

    if (notification.standardNotificationName === 'Install iOS') {
      const {
        viewStore: { appTitle, appName }
      } = this.props;
      const message = `To install the ${
        appTitle || appName || 'App'
      }(Safari only), tap share then 'Add to Home Screen'`;
      const options = { autoHideDuration: 3000 };
      // console.log('message', toJS(message));
      // console.log('options', toJS(options));
      addNotification({
        message: message,
        options: options
      });

      return removeStandardNotification(notification.standardNotificationName);
    }
    if (notification.standardNotificationName === 'Install PWA') {
      const {
        viewStore: { appTitle, appName }
      } = this.props;
      const message = `Install ${appTitle || appName || 'App'}?`;
      const options = {
        autoHideDuration: 15000,
        action: <NotificationButtonInstallPwa />
      };
      // console.log('message', toJS(message));
      // console.log('options', toJS(options));
      addNotification({
        message: message,
        options: options
      });

      return removeStandardNotification(notification.standardNotificationName);
    }
    if (notification.standardNotificationName === 'App Update') {
      // console.log('App Update', toJS(notification));
      const message = 'Update available!';
      const options = {
        action: <NotificationButtonAppUpdate />,
        variant: 'info',
        persist: true
      };

      addNotification({
        message: message,
        options: options
      });
      return removeStandardNotification(notification.standardNotificationName);
    }
    if (notification.standardNotificationName === 'Autodesk Login') {
      const message = 'Logged Into Autodesk';
      const options = { variant: 'success', autoHideDuration: 3000 };
      // console.log('message', toJS(message));
      // console.log('options', toJS(options));
      addNotification({
        message: message,
        options: options
      });

      return removeStandardNotification(notification.standardNotificationName);
    }
    //// console.log('notification', notification);
    //// console.log('options', options);
    //// console.log('message', message);
    // return this.handleNotifications(notification, true);
  };
  get watchStandardNotifications() {
    return (
      this.props.notificationStore?.standardNotifications?.map(noti => {
        return { ...noti };
      }) || []
    );
  }
  render() {
    return (
      <ErrorBoundary name="LayoutRouter">
        <LayoutRouter layouts={layouts} />
      </ErrorBoundary>
    );
  }
}

/**
 * Since App mounts the LayoutRouter, we need to manually inject the stores
 * to avoid race conditions found when using at this level injectStores.
 */
export default inject('notificationStore', 'viewStore')(observer(App));
