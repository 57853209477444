import HubUploadIcon from '@mui/icons-material/Publish';

const dockerLogsRoutes = [
  {
    path: '/project/api/docker-logs',
    subRoutes: ['ProjectDashboard'],
    // parentRoute: 'ProjectDashboard',
    layout: '/app',
    name: 'Docker Logs',
    projectRoute: true,
    icon: HubUploadIcon,
    component: () => import('./DockerLogs.Container'),
    requiresPermission: false,
    channels: ['docker-logs'],
    enableAutoFind: true,
    beforeEnter: async ({ rootStore }) => {
      // rootStore.dockerLogsStore.find();
    }
  }
];

export default dockerLogsRoutes;
