import LogViewerIcon from '@mui/icons-material/RssFeed';

const appLogViewerRoute = [
  {
    path: '/project/:currentProject/log-viewer',
    subRoutes: ['ProjectDashboard'],
    layout: '/app',
    name: 'Log Viewer',
    icon: LogViewerIcon,
    component: () => import('./LogViewer.Container'),
    requiresPermission: false,
    homeShortcut: false,
    projectRoute: true,
    sideBarShortcut: false,
    channels: ['log/:currentProject'],
    onEnter: async ({ rootStore, params }) => {
      const {
        logStore: { find: findLogs }
      } = rootStore;
      findLogs({
        query: {
          appName: '@compass/' + params?.currentProject,
          $limit: 2000,
          $sort: {
            createdDate: -1
          }
        }
      });
    }
  }
];

export default appLogViewerRoute;
