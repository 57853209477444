import { computed, makeAutoObservable, reaction, toJS } from 'mobx';

class MonitorManagementStore {
  sessionResults = '';
  sessionStatus = 'Ready';
  sessionHistory = [];
  newMonitorName = '';
  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
    if (this.rootStore?.monitorsControlStore?.service) {
      reaction(
        () =>
          computed(() =>
            this.rootStore.routerStore.currentPath.includes('/monitor-devices')
          ).get(),
        onMonitorRoute => {
          if (onMonitorRoute) {
            console.log(
              'Setting up monitor listeners',
              this.rootStore?.monitorsControlStore?.service
            );
            this.rootStore.monitorsControlStore.service.on('patched', data => {
              console.log('Monitor Ping', data);
              if (this.sessionResults) {
                this.setSessionResults('');
              }
              if (data?.commandResults) {
                this.setSessionResults(data.commandResults);
                const device = data.deviceName || data.deviceId || 'Unknown';
                this.rootStore.notificationStore.addNotification({
                  message: `${device} was pinged!`,
                  options: { variant: 'success' }
                });
                this.setSessionStatus('Success');
              }
              if (data?.commandError) {
                this.setSessionResults(data.commandError);
                const device = data.deviceName || data.deviceId || 'Unknown';
                this.rootStore.notificationStore.addNotification({
                  message: `${device} experienced and error`,
                  options: { variant: 'Error' }
                });
                this.setSessionStatus('Error');
              }
            });
            this.rootStore.monitorsControlStore.service.on(
              'commandResults',
              data => {
                console.log('Monitor commandResults', data);
                // if (data) {
                //   const device =
                //     data.monitorConfig?.deviceName ||
                //     data.monitorConfig?.deviceId ||
                //     'Unknown';
                //   this.rootStore.notificationStore.addNotification({
                //     message: `${device} was pinged!`,
                //     options: { variant: 'success' }
                //   });
                // }
              }
            );
            this.rootStore.monitorsControlStore.service.on('created', data => {
              console.log('Monitor service created');
              // if (data) {
              //   const device =
              //     data.monitorConfig?.deviceName ||
              //     data.monitorConfig?.deviceId ||
              //     'Unknown';
              //   this.rootStore.notificationStore.addNotification({
              //     message: `${device} was pinged!`,
              //     options: { variant: 'success' }
              //   });
              // }
            });

            this.currentDialogMonitorDispose = reaction(
              () => this.currentDialogMonitor,
              monitor => {
                if (
                  monitor &&
                  monitor.deviceName &&
                  monitor.deviceName !== this.newMonitorName
                ) {
                  this.setValue('newMonitorName', monitor.deviceName);
                }
              }
            );
          } else {
            this.rootStore.monitorsControlStore.service.removeListener(
              'created',
              () => console.log('removed listener for created')
            );
            this.rootStore.monitorsControlStore.service.removeListener(
              'patched',
              () => console.log('removed listener for patched')
            );
            this.rootStore.monitorsControlStore.service.removeListener(
              'commandResults',
              () => console.log('removed listener for commandResults')
            );
          }
        }
      );
    }
  }

  setValue = (key, value) => {
    this[key] = value;
  };

  setSessionResults = commandResults => {
    this.sessionResults = commandResults;
  };

  setSessionStatus = sessionStatus => {
    this.sessionStatus = sessionStatus;
  };
  pingDevice = async specificDevice => {
    const { monitorsControlStore } = this.rootStore;
    try {
      if (specificDevice) {
        await monitorsControlStore.create({
          deviceId: specificDevice,
          ping: true
        });
      } else {
        await monitorsControlStore.create({ allMonitors: true, ping: true });
      }
    } catch (err) {
      console.warn('Error', err);
    }
  };

  submitName = async () => {
    const {
      monitorsDevicesStore: { patch }
    } = this.rootStore;
    try {
      await patch(this.currentDialogMonitor.id, {
        deviceName: this.newMonitorName
      });
    } catch (err) {
      console.warn('Something went wrong', err);
    }
  };

  get listOfMonitors() {
    const { monitorsDevicesStore } = this.rootStore;
    if (monitorsDevicesStore?.entities?.length === 0) {
      return [];
    }
    return monitorsDevicesStore.entities.slice();
  }
  get activeMonitors() {
    const { channelStore, monitorsDevicesStore, userStore } = this.rootStore;
    const prodUser = userStore?.entities
      .slice()
      .find(i => i.email.includes('production'));
    if (prodUser) {
      const prodMonUSer = channelStore.entities
        .slice()
        .find(i => i.userId === prodUser.id);
      if (prodMonUSer && prodMonUSer.connections) {
        const monitorDevices = prodMonUSer.connections.filter(
          i => i.appName === '@compass/monitor-control'
        );
        if (monitorDevices?.length) {
          return monitorDevices.map(monitor => {
            const matchedDevice = monitorsDevicesStore.entities
              .slice()
              .find(device => device.deviceId === monitor.deviceId);
            if (matchedDevice) {
              return { monitor: toJS(monitor), control: toJS(matchedDevice) };
            }
            return { monitor: toJS(monitor), control: null };
          });
        }
      }
    }
    return [];
  }

  get currentDialogMonitor() {
    const {
      monitorsDevicesStore: { entities },
      routerStore: { queryParams }
    } = this.rootStore;
    return entities
      .slice()
      .find(monitor => monitor.deviceId === queryParams?.dialogContext);
  }
}

export default MonitorManagementStore;
