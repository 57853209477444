import { makeAutoObservable } from 'mobx';

class UserPermissionManagementStore {
  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  clearCurrentUserPermissions = async () => {
    const { params } = this.rootStore.routerStore;
    try {
      await this.rootStore.userPermissionsStore.remove(null, {
        userId: params.userId,
        appName: this.rootStore.appName
      });
    } catch (err) {
      console.warn('Something went wrong', err);
    }
  };

  get userList() {
    const { userStore } = this.rootStore;

    return userStore.entities.slice();
  }

  get currentUser() {
    const {
      routerStore: { params }
    } = this.rootStore;
    console.log('returning user from userList computed', this.userList);
    console.log('currentUser params', params);
    return this.userList
      .slice()
      .find(u => parseInt(u.id) === parseInt(params?.userId));
  }

  get currentUserPermissions() {
    const {
      userPermissionsStore: { entities: permissionEntities }
    } = this.rootStore;
    console.log('currentUser', this.currentUser);
    return permissionEntities
      .slice()
      .filter(p => parseInt(p.userId) === parseInt(this.currentUser?.id));
  }
}

export default UserPermissionManagementStore;
