import projectSpecificRoutes from './ProjectSpecificRoutes/projectSpecificRoutes';
import logViewerRoute from './LogViewer/logViewerRoute';
import versionManagerRoutes from './VersionManager/versionManagerRoutes';
import channelViewerRoutes from './ChannelViewer/channelViewerRoutes';
import devopsRoutes from './Devops/devopsRoutes';
import dockerVersionsRoutes from './DockerVersions/dockerVersionsRoutes';
import projectDashboardRoutes from './ProjectDashboard/projectDashboardRoutes';
import notificationManagementRoutes from './NotificationManagement/notificationManagementRoutes';

const projectRoutes = [
  ...projectDashboardRoutes,
  ...devopsRoutes,
  ...versionManagerRoutes,
  ...dockerVersionsRoutes,
  ...logViewerRoute,
  ...channelViewerRoutes,
  ...notificationManagementRoutes,
  ...projectSpecificRoutes
];

export default projectRoutes;
