import { databaseDate, databaseTime } from '@compass/utils';
import { makeAutoObservable } from 'mobx';
import { cpmUploadServiceClient } from '../utilStores/azureStorage';
const containerClient = cpmUploadServiceClient.getContainerClient('');

class CPMUploadStore {
  items = [];
  showAll = false;
  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }
  setShowAll = () => {
    this.showAll = !this.showAll;
  };

  listRPMVersions = async () => {
    let blobs = await containerClient.listBlobsFlat();
    let blobList = [];
    for await (const blob of blobs) {
      blobList.push(blob);
    }
    console.log('blobList', blobList);
    this.items = blobList;
    return blobList;
  };
  handleRPMFiles = async (event, folderName, fileName) => {
    const file = event.target.files[0];
    const files = Array.from(event.target.files);

    if (fileName && file.name !== fileName) throw Error('Not the right file');

    Promise.all(
      files.map(async file => {
        return await this.uploadFileToBlob(file, file.name);
      })
    ).then(async () => {
      this.listRPMVersions();
      const latest = await this.rootStore.appVersionStore.find({
        query: {
          appName: '@compass/plugin-manager',
          $sort: { version: -1 },
          $limit: 1
        }
      });
      if (latest && latest.data && latest.data.length) {
        await this.rootStore.appVersionStore.create({
          appName: '@compass/plugin-manager',
          version: latest.data[0].version,
          publishedBy: this.rootStore.authStore.currentUser.email,
          releaseNotes: '',

          releaseDate: databaseDate(),
          releaseTime: databaseTime(),

          required: false
        });
      }
    });
  };
  uploadFileToBlob = async (file, fileName) => {
    if (!file) return [];

    const blobClient = containerClient.getBlockBlobClient(`${fileName}`);
    console.log('blobClient', blobClient);

    // upload file
    const uploadResult = await blobClient.uploadData(file, {
      // blockSize: 4 * 1024 * 1024, // 4MB block size
      concurrency: 20, // 20 concurrency
      onProgress: ev => console.log(ev)
    });
    console.log('uploadResult', uploadResult);
    if (
      uploadResult &&
      blobClient.url &&
      fileName.includes('exe') &&
      !fileName.includes('blockmap')
    ) {
      const latestBlobClient =
        await containerClient.getBlobClient('CPM Latest.exe');
      await latestBlobClient.beginCopyFromURL(blobClient.url);
    }
  };

  get entities() {
    if (!this.showAll) {
      const sortedItems = this.items.slice().sort((a, b) => {
        const timeA = new Date(a.properties.createdOn).getTime();
        const timeB = new Date(b.properties.createdOn).getTime();

        return timeB - timeA;
      });
      const latestYML = sortedItems.find(i => i.name === 'latest.yml');
      const builderEffectiveConfig = sortedItems.find(
        i => i.name === 'builder-effective-config.yaml'
      );
      return [...sortedItems.slice(0, 2), latestYML, builderEffectiveConfig];
    }
    const sortedItems = this.items.slice().sort((a, b) => {
      const timeA = new Date(a.properties.createdOn).getTime();
      const timeB = new Date(b.properties.createdOn).getTime();

      return timeB - timeA;
    });
    const latestYML = sortedItems.find(i => i.name === 'latest.yml');
    const builderEffectiveConfig = sortedItems.find(
      i => i.name === 'builder-effective-config.yaml'
    );
    return [
      ...sortedItems.slice(0, 2),
      latestYML,
      builderEffectiveConfig,
      ...sortedItems
        .slice(2)
        .filter(
          i =>
            i.name !== 'latest.yml' &&
            i.name !== 'builder-effective-config.yaml'
        )
    ];
  }
}

export default CPMUploadStore;
