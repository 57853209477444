import DeviceManagementIcon from '@mui/icons-material/MobileFriendly';

const entityViewerRoutes = [
  {
    path: '/entity-viewer/:servicePath',
    layout: '/app',
    name: 'Entity Viewer',
    icon: DeviceManagementIcon,
    defaultParams: { servicePath: 'absorption' },
    component: () => import('./EntityViewer.Container'),
    requiresPermission: false,
    homeShortcut: true,
    sideBarShortcut: true,
    channels: [':servicePath'],
    beforeEnter: async ({ rootStore }) => {
      rootStore.entityViewerStore.findServiceStores();
    }
  }
];

export default entityViewerRoutes;
