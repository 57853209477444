import { makeAutoObservable, runInAction } from 'mobx';
import { uuid, uriToCamel } from '@compass/utils';

const LIMIT = 1000;
class FindAllStore {
  workerError;

  allWorkers = {};

  requestQueue = {};

  debugMode = false;

  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
    // console.log(MyWorker);
  }
  handleDebugMode = () => {
    this.debugMode = !this.debugMode;
  };
  createWorker = workerId => {
    if (!window.Worker) {
      console.log('Error Creating Worker');
      if (this.rootStore.authStore?.currentUser?.department === 'Developer') {
        this.rootStore.notificationStore?.addNotification({
          message: `Error Creating Worker - Worker API Unavailable`
        });
      }
      this.rootStore.logStore
        ?.create({
          message: 'Error Creating Worker',
          level: 'error',
          payload: { message: 'Worker was not available' }
        })
        .catch(err => {
          console.log('Error', err);
        });
      return new Error('Requires a browser with worker');
    }
    const workerResult = new Worker(
      new URL('./findAllItemUpdatesWorker.js', import.meta.url),
      { type: 'module' }
    );
    if (workerResult) {
      this.setWorker(workerResult, workerId);
      return workerResult;
    }
    return 'Failed';
  };
  setWorker = (newWorker, workerId) => {
    if (newWorker && workerId) {
      this.allWorkers = { ...this.allWorkers, [workerId]: newWorker };
      this.addWorkerListener(newWorker);
    }
  };
  addWorkerListener = currentWorker => {
    if (!currentWorker) throw Error('No worker');
    currentWorker.onmessage = e => {
      if (this.debugMode)
        console.log('Main received a message back from worker');
      if (this.debugMode) console.log('Message:', e);
      if (e.data) {
        return this.setWorkerResponse(JSON.parse(e.data));
      }
      console.log('Response did not have data', e);
    };
    currentWorker.onerror = e => {
      if (this.debugMode)
        console.warn('Main received an error back from worker');
      console.warn('Error:', e);
      if (e.data) {
        return this.setWorkerError(e.data);
      }
    };
    currentWorker.onmessageerror = e => {
      console.warn('Main received an message error back from worker');
      console.warn('Error:', e);
      if (e.data) {
        return this.setWorkerError(e.data);
      }
    };
  };

  createLoopWorkers = (service, query, options, total) => {
    const { token } = this.rootStore.authStore;
    const loopCount = Math.ceil(total / LIMIT);
    if (this.debugMode) console.log('loopCount', loopCount);

    const looper = Array.from(Array(loopCount).keys());
    return looper.map(run => {
      const requestId = uuid();
      const workerId = uuid();

      const storeName = `${uriToCamel(service)}Store`;
      let checkStorage = false;
      if (this.rootStore[storeName]?.storage) {
        checkStorage = true;
      }

      const currentWorker = this.createWorker(workerId);
      const currentRequest = {
        service: service,
        checkStorage: checkStorage,
        query: {
          $limit: LIMIT,
          $skip: LIMIT * run,
          ...query
        },
        requestId: requestId,
        workerId: workerId,
        startRequest: new Date().valueOf(),
        options: options,
        completed: false
      };
      if (this.debugMode) console.log('currentRequest', currentRequest);
      currentWorker.postMessage({
        auth: {
          token: token
        },
        request: currentRequest,
        mode: this.rootStore.mode
      });
      this.addToRequestQueue(currentRequest);
      return currentRequest;
    });
  };

  findAllUpdates = async (service, query, options) => {
    if (this.debugMode) console.log('service', service);
    if (this.debugMode) console.log('query', query);
    const { token } = this.rootStore.authStore;
    const requestId = uuid();
    const workerId = uuid();

    const currentService = this.rootStore.app.service(service);

    const { total } = await currentService.find({
      query: { $limit: 0, ...query }
    });
    if (total > 4000) {
      console.group('FindAll Warning');
      console.warn(
        `FindAll doing a really large find (iterating ${total} items), add a query`
      );
      console.warn('service', service);
      console.warn('query', query);
      console.warn('options', options);
      console.groupEnd();
    }
    if (this.debugMode) console.log('LIMIT', LIMIT);
    if (total > LIMIT) {
      if (this.debugMode) console.log('total over limit', service, total);
      return this.createLoopWorkers(service, query, options, total);
    }
    if (this.debugMode) console.log('total', service, total);

    let checkStorage = false;
    const storeName = `${uriToCamel(service)}Store`;
    if (this.rootStore[storeName]?.storage) {
      checkStorage = true;
    }

    const currentWorker = this.createWorker(workerId);
    const currentRequest = {
      service: service,
      checkStorage: checkStorage,
      query: { ...query, $limit: total },
      requestId: requestId,
      workerId: workerId,
      startRequest: new Date().valueOf(),
      options: options,
      completed: false
    };
    if (this.debugMode) console.log('currentRequest', currentRequest);
    currentWorker.postMessage({
      auth: {
        token: token
      },
      request: currentRequest,
      mode: this.rootStore.mode
    });
    this.addToRequestQueue(currentRequest);
    return currentRequest;
  };
  setWorkerResponse = async workerResponse => {
    if (this.debugMode) console.log('workerResponse', workerResponse);
    if (workerResponse.error) {
      if (workerResponse.workerId) {
        console.error('Killing worker that had error', workerResponse.workerId);
        this.destroyWorker(workerResponse.workerId);
      }
      return console.error('Something went wrong:', workerResponse.error);
    }
    if (!workerResponse.requestId) {
      return console.error('Something went wrong:', workerResponse);
    }
    const foundRequest = this.requestQueue[workerResponse.requestId];
    if (!workerResponse.workerResult || !workerResponse.workerResult.length) {
      if (this.debugMode) console.log('Worker had no updates', workerResponse);
      runInAction(async () => {
        this.requestQueue[workerResponse.requestId] = {
          ...foundRequest,
          completed: true
        };
      });
      return this.destroyWorker(workerResponse.workerId);
    }
    if (foundRequest) {
      if (this.debugMode) console.log('foundRequest', foundRequest);

      const { service } = foundRequest;
      const storeName = `${uriToCamel(service)}Store`;
      if (this.debugMode) console.log('storeName', storeName);

      if (
        this.rootStore[storeName] &&
        workerResponse.workerResult &&
        workerResponse.workerResult === 'Request came back empty'
      ) {
        console.log('Worker result was empty');
        this.requestQueue[workerResponse.requestId] = {
          ...foundRequest,
          completed: true
        };
        return this.destroyWorker(workerResponse.workerId);
      }

      if (
        this.rootStore[storeName] &&
        workerResponse.workerResult &&
        workerResponse.workerResult.length
      ) {
        if (this.debugMode)
          console.log(
            'workerResponse.workerResult',
            workerResponse.workerResult
          );
        const currentStore = this.rootStore[storeName];

        currentStore.mergeItems(workerResponse.workerResult);

        if (currentStore?.storage) {
          workerResponse.workerResult.map(item => {
            return currentStore.storage
              .setItem(`${item.id}`, { ...item })
              .catch(err => console.warn('Error', err));
          });
        }

        if (foundRequest?.options?.reportTime) {
          const completeRequest = new Date().valueOf();
          const processTime = completeRequest - foundRequest.startRequest;
          if (this.debugMode)
            console.log(
              `${foundRequest.service} took ${processTime}ms to get ${workerResponse.workerResult.length} items`
            );
          if (this.rootStore.notificationStore) {
            const { notificationStore } = this.rootStore;
            notificationStore.addNotification({
              message: `Updated ${workerResponse.workerResult.length} items for ${storeName} in ${processTime}ms`,
              options: { variant: 'Success', autoHideDuration: 3000 }
            });
          }
        } else if (foundRequest?.options?.reportService) {
          if (this.rootStore.notificationStore) {
            const { notificationStore } = this.rootStore;
            notificationStore.addNotification({
              message: `Updated ${workerResponse.workerResult.length} items for ${service}`,
              options: { variant: 'Success', autoHideDuration: 3000 }
            });
          }
        } else if (this.debugMode) {
          const completeRequest = new Date().valueOf();
          const processTime = completeRequest - foundRequest.startRequest;

          console.log(
            `${foundRequest.service} took ${processTime}ms to get ${workerResponse.workerResult.length} items`
          );
        }
      }
      runInAction(() => {
        if (this.debugMode)
          console.log(
            'this.requestQueue[workerResponse.requestId] before',
            this.requestQueue[workerResponse.requestId]
          );
        this.requestQueue[workerResponse.requestId] = {
          ...foundRequest,
          completed: true,
          syncedItems: workerResponse.workerResult.length
        };
        if (this.debugMode)
          console.log(
            'this.requestQueue[workerResponse.requestId] after',
            this.requestQueue[workerResponse.requestId]
          );
      });

      if (workerResponse.workerId) {
        this.destroyWorker(workerResponse.workerId);
      }
    } else {
      workerResponse.workerId && this.destroyWorker(workerResponse.workerId);
    }
  };
  setWorkerError = workerError => {
    if (this.debugMode) console.log('workerError', workerError);
    this.workerError = workerError;
  };
  addToRequestQueue = newRequest => {
    this.requestQueue = {
      ...this.requestQueue,
      [newRequest.requestId]: { ...newRequest }
    };
  };
  destroyWorker = workerId => {
    if (this.debugMode) console.log('destroyWorker workerId', workerId);
    const foundWorker = this.allWorkers[workerId];
    if (foundWorker) {
      if (this.debugMode) console.log('Terminating worker', foundWorker);
      foundWorker.terminate();
      delete this.allWorkers[workerId];

      if (this.debugMode)
        console.log('Terminated worker, remaining workers', {
          ...this.allWorkers
        });
    }
  };
}

export default FindAllStore;
