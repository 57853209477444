import { makeAutoObservable } from 'mobx';

class DockerLogsStore {
  levels = ['info', 'debug', 'warning', 'error'];
  infoLevel = true;
  debugLevel = true;
  warningLevel = true;
  errorLevel = true;

  dockerLogsFilter = '';

  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  setDockerLogsFilter = value => {
    this.dockerLogsFilter = value;
  };
  setLevelVisibility = (level, value) => {
    this[level] = value;
  };

  get entities() {
    return this.rootStore.dockerLogsStore.entities
      .slice()
      .filter(i => {
        if (!this.infoLevel && i.level === 'info') return false;
        if (!this.debugLevel && i.level === 'debug') return false;
        if (!this.warningLevel && i.level === 'warn') return false;
        if (!this.errorLevel && i.level === 'error') return false;
        if (!this.dockerLogsFilter) return true;
        return Object.entries(i)
          .filter(([logKey, logValue]) => logKey !== 'level')
          .filter(([logKey, logValue]) => !!logValue)
          .some(([logKey, logValue]) =>
            `${logValue}`
              .toLowerCase()
              .includes(this.dockerLogsFilter.toLowerCase())
          );
      })
      .map(i => {
        return { ...i };
      })
      .reverse();
  }
}

export default DockerLogsStore;
