import { databaseDate } from '@compass/utils';
import ReportViewerIcon from '@mui/icons-material/Summarize';

const reportViewerRoutes = [
  {
    path: '/report-viewer',
    layout: '/app',
    name: 'Report Viewer',
    icon: ReportViewerIcon,
    defaultQueryParams: {
      reportDate: databaseDate()
    },
    component: () => import('./ReportViewer.Container'),
    requiresPermission: false,
    homeShortcut: true,
    sideBarShortcut: true,
    onEnter: async ({ rootStore, queryParams }) => {
      rootStore.reportPanelHoldStore.find({
        query: { reportDate: queryParams.reportDate }
      });
    },
    onParamsChange: async ({ rootStore }) => {
      rootStore.routerStore.triggerEnterSideEffects();
    }
  }
];

export default reportViewerRoutes;
