import ChannelViewerIcon from '@mui/icons-material/LeakAdd';

const notificationManagementRoutes = [
  {
    path: '/project/:currentProject/notifications',
    layout: '/app',
    name: 'Notifications',
    icon: ChannelViewerIcon,
    defaultQueryParams: {
      tabName: 'Connections'
    },
    component: () => import('./NotificationManagement.Container'),
    requiresPermission: false,
    homeShortcut: true,
    sideBarShortcut: true,
    projectRoute: true,
    channels: [
      'users',
      'push-device-connection',
      'push-notification',
      'push-trigger-send',
      'user-subscription',
      'email/history'
    ],
    beforeEnter: async ({ rootStore }) => {
      const {
        pushDeviceConnectionStore,
        userSubscriptionStore,
        userStore,
        findAllStore: { findAllUpdates },
        notificationSubscriptionsStore
      } = rootStore;
      notificationSubscriptionsStore.find();
      userStore.find();
      pushDeviceConnectionStore.find();
      userSubscriptionStore.find();
      findAllUpdates('push-notification');
      findAllUpdates('push-trigger-send');
      findAllUpdates('email/history');
    },
    beforeExit: async ({ rootStore }) => {
      rootStore.routerStore.clearQueryParams();
    }
  }
];

export default notificationManagementRoutes;
