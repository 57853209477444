import { groupBy, isSameId, sortByVersion, uniqueArray } from '@compass/utils';
import { makeAutoObservable, reaction, runInAction } from 'mobx';

class ChannelViewerStore {
  pushRoute = '';
  hideEmptyChannelUsersFilter = true;
  polling = 10;

  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;

    this.tokenDispose = reaction(
      () => this.connectionDialog || this.tokenDialog,
      value => {
        if (value) {
          this.findUserTokens(value.id);
        }
      }
    );
    this.isWatchingChannelsDispose = reaction(
      () => this.watchingChannels,
      userWatchingChannels => {
        if (userWatchingChannels) {
          this.startWatchingChannels();
        } else {
          this.stopWatchingChannels();
        }
      }
    );
  }
  dispose = () => {
    this.disposer && this.disposer();
    this.intervalDispose && clearInterval(this.intervalDispose);
    this.tokenDispose && this.tokenDispose();
  };

  startWatchingChannels = () => {
    this.rootStore.app.service('channel').on('created', data => {
      if (data.userId) {
        const { userStore } = this.rootStore;
        const checkUserStore = userStore.entities.find(
          user => parseInt(user.id) === parseInt(data.userId)
        );
        if (!checkUserStore) {
          userStore.get(data.userId);
        }
      }
    });
    this.rootStore.app.service('channel').on('patched', data => {
      if (data.userId) {
        const { userStore } = this.rootStore;
        const checkUserStore = userStore.entities.find(
          user => parseInt(user.id) === parseInt(data.userId)
        );
        if (!checkUserStore) {
          userStore.get(data.userId);
        }
      }
    });
    this.rootStore.app.service('channel').on('removed', data => {
      console.log('channelStoreRemoved', data);
    });
  };
  stopWatchingChannels = () => {
    this.rootStore.app.service('channel').removeListener('created');
    this.rootStore.app.service('channel').removeListener('patched');
    this.rootStore.app.service('channel').removeListener('removed');
  };

  findUserTokens = async userId => {
    const { find: findToken } = this.rootStore.refreshTokenStore;
    try {
      await findToken({ query: { userId: userId } });
    } catch (err) {
      console.warn('Error findUserTokens', err);
    }
  };

  revokeToken = async (user, token) => {
    const { patch } = this.rootStore.refreshTokenStore;
    try {
      await patch(null, {
        refreshToken: token,
        userId: user.id
      });
    } catch (err) {
      console.warn('Error revokeToken', err);
    }
  };

  logoutUser = async (user, token) => {
    const { remove } = this.rootStore.refreshTokenStore;
    try {
      await remove(null, {
        query: { refreshToken: token, userId: user.id }
      });
    } catch (err) {
      console.warn('Error revokeToken', err);
    }
  };

  openConnectionDialog = connectionId => {
    this.rootStore.routerStore.goTo(
      this.rootStore.routerStore.currentRoute,
      this.rootStore.routerStore.params,
      { connectionId: connectionId, dialogName: 'Connection Dialog' }
    );
  };

  closeConnectionDialog = () => {
    this.openConnectionDialog('');
  };

  hideEmptyChannelUsers = () => {
    this.hideEmptyChannelUsersFilter = !this.hideEmptyChannelUsersFilter;
  };

  getCurrentChannels = async () => {
    return await this.rootStore.channelStore.find();
  };
  setPushRoute = value => {
    this.pushRoute = value;
  };
  pushUserToRoute = async () => {
    if (!this.connectionDialogUser) return Error('needs connectionDialogUser');
    let pushedUser;
    try {
      pushedUser = await this.rootStore.channelStore.update(
        this.connectionDialog,
        {
          routeName: this.pushRoute,
          appName: this.connectionDialogUser.appName,
          version: this.connectionDialogUser.version
        }
      );
      runInAction(() => {
        if (pushedUser) {
          this.pushRoute = '';
          this.rootStore.channelStore.find();
          this.rootStore.userStore.find();
        }
      });
    } catch (err) {
      console.warn('pushUserToRoute Error', err);
    }

    return pushedUser;
  };

  get connectionDialogUser() {
    if (!this.connectionDialog) return null;
    const connection = this.channelUserItems.find(
      channelUser => channelUser.connectionId === this.connectionDialog
    );
    return connection;
  }

  get channelUserItems() {
    return this.rootStore.channelStore.entities.slice().map(i => {
      if (!i.userId) {
        return { ...i, userId: 'anonymous' };
      }
      return { ...i, userId: parseInt(i.userId) };
    });
  }

  get currentAppVersions() {
    const {
      appVersionStore: { entities }
    } = this.rootStore;
    if (!entities || !entities.length) return [];
    return entities
      .slice()
      .filter(
        appVersion =>
          appVersion && appVersion.version && appVersion.version.includes('.')
      )
      .filter(appVersion => appVersion.appName.includes(this.currentProject));
  }

  get latestAppVersion() {
    if (!this.currentAppVersions || !this.currentAppVersions.length) return '';
    const sortedVersions = sortByVersion(this.currentAppVersions);
    return sortedVersions[0].version || '';
  }

  get channelUsersByApp() {
    const filteredConnections = this.channelUserItems.filter(
      i => 'appName' in i
    );
    return filteredConnections.length > 0
      ? groupBy(filteredConnections, 'appName')
      : {};
  }

  get currentAppChannelUsers() {
    if (!this.channelUserItems || !this.channelUserItems.length) return [];
    if (this.currentProject === 'api') {
      // Return all users connected to the server instead of per app
      const channelByUser = groupBy(this.channelUserItems, 'userId');
      const perUserChannels = Object.keys(channelByUser).map(userId => {
        return { userId: userId, connections: channelByUser[userId] };
      });
      return perUserChannels;
    }
    let currentAppChannelConnections =
      this.channelUsersByApp['@compass/' + this.currentProject] &&
      this.channelUsersByApp['@compass/' + this.currentProject].length
        ? this.channelUsersByApp['@compass/' + this.currentProject].filter(
            c => c.userId
          )
        : [];
    if (currentAppChannelConnections?.length > 0) {
      const channelByUser = groupBy(currentAppChannelConnections, 'userId');
      const perUserChannels = Object.keys(channelByUser).map(userId => {
        return { userId: userId, connections: channelByUser[userId] };
      });
      return perUserChannels;
    }

    return currentAppChannelConnections;
  }

  get currentChannelUser() {
    const { routerStore } = this.rootStore;
    return this.currentAppChannelUsers.find(u =>
      isSameId(u.userId, routerStore?.params?.userId)
    );
  }
  get currentChannelUserConnections() {
    if (!this.currentChannelUser?.connections?.length) return [];
    return [
      ...this.currentChannelUser.connections.map(connection => {
        return {
          ...connection,
          routeChannels: connection.routeChannels?.join(' && ')
        };
      })
    ];
  }

  get userToken() {
    const {
      refreshTokenStore: { entities }
    } = this.rootStore;
    if (!this.connectionDialogUser) return {};

    const { deviceId, userId } = this.connectionDialogUser;

    if (!deviceId || !userId) return {};
    return entities
      .slice()
      .find(
        token =>
          parseInt(token.userId) === parseInt(userId) &&
          token.deviceId === deviceId
      );
  }

  get connectionDialog() {
    const { queryParams } = this.rootStore.routerStore;

    return queryParams?.dialogName === 'Connection Manager Dialog'
      ? queryParams?.dialogContext
      : null;
  }

  get tokenDialog() {
    const { queryParams } = this.rootStore.routerStore;
    if (
      !queryParams ||
      queryParams.dialogName !== 'Token Viewer' ||
      !queryParams.dialogContext
    ) {
      return null;
    }
    return queryParams.dialogContext;
  }

  get tokenDialogUser() {
    return this.rootStore.userStore.entities.find(
      user => user.id === this.tokenDialog
    );
  }

  get watchingChannels() {
    const { routerStore } = this.rootStore;
    if (routerStore?.currentPath.includes('/channel-viewer')) return true;
    return false;
  }

  get channelViewerColumnHeaders() {
    if (this.currentProject === 'api')
      return [
        { key: 'userId', title: 'User Id', dataType: 'number', width: 80 },
        { key: 'userName', title: 'User Name', dataType: 'string' },
        {
          key: 'connectionCount',
          title: 'Connection Count',
          dataType: 'number'
        },
        {
          key: 'currentApps',
          title: 'Current Apps',
          dataType: 'number',
          width: 500
        }
        // {
        //   key: 'lastUpdateDate',
        //   title: 'Last Update Date',
        //   dataType: 'date'
        // },
        // {
        //   key: 'lastUpdateTime',
        //   title: 'Last Update Time',
        //   dataType: 'time'
        // }
      ];
    return [
      { key: 'userId', title: 'User Id', dataType: 'number', width: 80 },
      { key: 'userName', title: 'User Name', dataType: 'string' },
      {
        key: 'connectionCount',
        title: 'Connection Count',
        dataType: 'number'
      },
      {
        key: 'currentVersions',
        title: 'Current Versions',
        dataType: 'string'
      }
      // {
      //   key: 'lastUpdateDate',
      //   title: 'Last Update Date',
      //   dataType: 'date'
      // },
      // {
      //   key: 'lastUpdateTime',
      //   title: 'Last Update Time',
      //   dataType: 'time'
      // }
    ];
  }

  get channelUserColumnHeaders() {
    const generalColumns = [
      { key: 'version', title: 'Version', dataType: 'string', width: 100 },
      {
        key: 'connectionId',
        title: 'Connection Id',
        dataType: 'string',
        width: 180
      },
      {
        key: 'updatedDate',
        title: 'Updated Date',
        dataType: 'string',
        width: 120
      },
      {
        key: 'updatedTime',
        title: 'Updated Time',
        dataType: 'string',
        width: 120
      },
      {
        key: 'routeChannels',
        title: 'Route Channels',
        dataType: 'string',
        width: 600
      }
    ];
    if (this.currentProject === 'api')
      return [
        { key: 'appName', title: 'User Name', dataType: 'string', width: 180 },
        ...generalColumns
      ];
    return generalColumns;
  }

  get channelUserHeaders() {
    if (this.currentProject === 'api')
      return [
        'appName',
        'version',
        'connectionId',
        'updatedDate',
        'updatedTime',
        'routeChannels'
      ];
    return [
      'version',
      'connectionId',
      'updatedDate',
      'updatedTime',
      'routeChannels'
    ];
  }

  get currentProject() {
    const {
      routerStore: { params }
    } = this.rootStore;
    return params?.currentProject;
  }

  get entities() {
    const {
      userStore: { entities },
      routerStore: { currentRoute }
    } = this.rootStore;
    if (currentRoute.title === 'Channel Viewer User') {
      return this.currentChannelUserConnections;
    }
    const users = this.currentAppChannelUsers.map(channelUser => {
      if (channelUser.userId === 'anonymous') {
        return {
          userId: channelUser.userId,
          userName: channelUser.userId,
          connectionCount: channelUser?.connections?.length,
          currentApps: 'unknown'
        };
      }
      const foundUser = entities.find(user =>
        isSameId(user.id, channelUser.userId)
      );
      if (this.currentProject === 'api') {
        return {
          userId: channelUser?.userId,
          userName: foundUser?.email?.replace('@waltersandwolf.com', ''),
          connectionCount: channelUser?.connections?.length,
          currentApps: uniqueArray(
            channelUser?.connections.map(connection =>
              connection?.appName
                ? connection.appName.replace('@compass/', '')
                : 'unknown'
            )
          ).join(', ')
        };
      }

      return {
        userId: channelUser?.userId,
        userName: foundUser?.email?.replace('@waltersandwolf.com', ''),
        connectionCount: channelUser?.connections?.length,
        currentVersions: uniqueArray(
          channelUser?.connections.map(connection =>
            connection?.version ? connection.version : 'unknown'
          )
        ).join(', ')
      };
    });
    return [...users];
  }
}

export default ChannelViewerStore;
