import { uniqueArray, uriToCamel, uuid } from '@compass/utils';
import { makeAutoObservable, toJS } from 'mobx';

const getDupes = items =>
  items.filter((item, index) => items.indexOf(item) !== index);
class DbAuditStore {
  worker;
  workerResponse;
  workerError;

  allWorkers = {};

  requestQueue = [];

  debugMode = false;

  totals = {
    panelRevit: 0,
    panelPlant: 0,
    panelYard: 0,
    panelField: 0,
    job: 0,
    jobPlant: 0,
    ifcStatus: 0
  };

  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
    // console.log(MyWorker);
  }
  handleDebugMode = () => {
    this.debugMode = !this.debugMode;
  };
  setTotal = (serviceName, total) => {
    console.log('this.totals', toJS(this.totals));
    this.totals[serviceName] = total;
    console.log('this.totals', toJS(this.totals));
  };

  findPanelRevitTotals = () => {
    const { panelRevitStore, notificationStore } = this.rootStore;
    panelRevitStore
      .find({
        query: { $limit: 0 }
      })
      .then(totalResponse => {
        console.log('panelRevitStore', totalResponse);
        this.setTotal('panelRevit', totalResponse?.total || 0);
      })
      .catch(err => {
        notificationStore.addNotification({
          message: err.message,
          options: { variant: 'warning' }
        });
      });
  };

  findPanelTotals = () => {
    const {
      panelPlantStore,
      panelYardStore,
      panelFieldStore,
      notificationStore
    } = this.rootStore;
    this.findPanelRevitTotals();
    panelPlantStore
      .find({
        query: { $limit: 0 }
      })
      .then(totalResponse => {
        console.log('panelPlantStore', totalResponse);
        this.setTotal('panelPlant', totalResponse?.total || 0);
      })
      .catch(err => {
        notificationStore.addNotification({
          message: err.message,
          options: { variant: 'warning' }
        });
      });
    panelYardStore
      .find({
        query: { $limit: 0 }
      })
      .then(totalResponse => {
        console.log('panelYardStore', totalResponse);
        this.setTotal('panelYard', totalResponse?.total || 0);
      })
      .catch(err => {
        notificationStore.addNotification({
          message: err.message,
          options: { variant: 'warning' }
        });
      });
    panelFieldStore
      .find({
        query: { $limit: 0 }
      })
      .then(totalResponse => {
        console.log('panelFieldStore', totalResponse);
        this.setTotal('panelField', totalResponse?.total || 0);
      })
      .catch(err => {
        notificationStore.addNotification({
          message: err.message,
          options: { variant: 'warning' }
        });
      });
  };

  findJobTotals = () => {
    const { jobStore, jobPlantStore, notificationStore } = this.rootStore;

    jobStore
      .find({
        query: { $limit: 0 }
      })
      .then(totalResponse => {
        console.log('jobStore', totalResponse);
        this.setTotal('job', totalResponse?.total || 0);
      })
      .catch(err => {
        notificationStore.addNotification({
          message: err.message,
          options: { variant: 'warning' }
        });
      });
    jobPlantStore
      .find({
        query: { $limit: 0 }
      })
      .then(totalResponse => {
        console.log('jobPlantStore', totalResponse);
        this.setTotal('jobPlant', totalResponse?.total || 0);
      })
      .catch(err => {
        notificationStore.addNotification({
          message: err.message,
          options: { variant: 'warning' }
        });
      });
  };

  findIfcTotals = () => {
    const { ifcStatusStore, notificationStore } = this.rootStore;

    ifcStatusStore
      .find({
        query: { $limit: 0 }
      })
      .then(totalResponse => {
        console.log('ifcStatusStore', totalResponse);
        this.setTotal('ifcStatus', totalResponse?.total || 0);
      })
      .catch(err => {
        notificationStore.addNotification({
          message: err.message,
          options: { variant: 'warning' }
        });
      });
  };

  createWorker = workerId => {
    if (!window.Worker) {
      throw new Error('Requires a browser with worker');
    }
    const workerResult = new Worker(
      new URL('./webWorker.js', import.meta.url),
      { type: 'module' }
    );
    if (workerResult) {
      this.setWorker(workerResult, workerId);
      return workerResult;
    }
    return 'Failed';
  };
  setWorker = (newWorker, workerId) => {
    if (newWorker && workerId) {
      this.allWorkers = { ...this.allWorkers, [workerId]: newWorker };
      this.addWorkerListener(newWorker);
    }
  };
  addWorkerListener = currentWorker => {
    if (!currentWorker) throw Error('No worker');
    currentWorker.onmessage = e => {
      if (this.debugMode)
        console.log('Main received a message back from worker');
      if (this.debugMode) console.log('Message:', e);
      this.setWorkerResponse(JSON.parse(e.data));
    };
    currentWorker.onerror = e => {
      console.warn('Main received an error back from worker');
      console.warn('Error:', e);
      this.setWorkerError(e.data);
    };
  };
  postMessage = () => {
    if (!this.worker) throw Error('No worker');
    this.worker.postMessage({
      auth: {
        token: this.rootStore.authStore.token
      },
      request: {
        service: 'log',
        method: 'find'
      },
      apiOptions: { uriOverride: this.rootStore?.apiOptions?.uriOverride }
    });
    return 'Requested';
  };

  findAll = (service, query) => {
    const { token } = this.rootStore.authStore;
    const requestId = uuid();
    const workerId = uuid();
    const currentWorker = this.createWorker(workerId);
    const currentRequest = {
      service: service,
      query: query,
      requestId: requestId,
      workerId: workerId,
      startRequest: new Date().valueOf()
    };
    console.log('currentRequest', currentRequest);
    currentWorker.postMessage({
      auth: {
        token: token
      },
      request: currentRequest,
      apiOptions: { uriOverride: this.rootStore?.apiOptions?.uriOverride }
    });
    this.addToRequestQueue(currentRequest);
    return 'Requested';
  };
  setWorkerResponse = async value => {
    if (this.debugMode) console.log('workerResponse', value);
    const foundRequestIndex = this.requestQueue.findIndex(
      r => r.requestId === value.requestId
    );
    if (foundRequestIndex > -1) {
      if (this.debugMode) console.log('foundRequestIndex', foundRequestIndex);
      if (this.debugMode)
        console.log(
          'this.requestQueue[foundRequestIndex]',
          this.requestQueue[foundRequestIndex]
        );
      this.requestQueue[foundRequestIndex] = {
        ...this.requestQueue[foundRequestIndex],
        completed: true
      };
      const { service } = this.requestQueue[foundRequestIndex];
      const storeName = `${uriToCamel(service)}Store`;
      if (this.debugMode) console.log('storeName', storeName);
      if (this.debugMode)
        console.log(
          'this.requestQueue[foundRequestIndex]',
          this.requestQueue[foundRequestIndex]
        );
      if (
        this.rootStore[storeName] &&
        value.workerResult &&
        value.workerResult.length
      ) {
        if (this.debugMode)
          console.log(
            'This is where we should try replaceAllItems',
            value.workerResult
          );
        const currentStore = this.rootStore[storeName];
        console.log('currentStore', currentStore);
        currentStore.replaceAllItems(value.workerResult);

        const completeRequest = new Date().valueOf();
        const processTime =
          completeRequest - this.requestQueue[foundRequestIndex].startRequest;
        console.log(
          `${this.requestQueue[foundRequestIndex].service} took ${processTime}ms to get ${value.workerResult.length} items`
        );
        if (this.rootStore.notificationStore) {
          const { notificationStore } = this.rootStore;
          notificationStore.addNotification({
            message: `Updated ${value.workerResult.length} items for ${storeName} in ${processTime}ms`,
            options: { variant: 'Success', autoHideDuration: 3000 }
          });
        }
      }
      if (value.workerId) {
        console.log('value.workerId', value.workerId);
        this.destroyWorker(value.workerId);
      }
    }
  };
  setWorkerError = value => {
    if (this.debugMode) console.log('workerError', value);
    this.workerError = value;
  };
  addToRequestQueue = newRequest => {
    this.requestQueue.push({ ...newRequest, completed: false });
  };
  destroyWorker = workerId => {
    if (this.debugMode) console.log('destroyWorker workerId', workerId);
    const foundWorker = this.allWorkers[workerId];
    if (foundWorker) {
      if (this.debugMode) console.log('Terminating worker', foundWorker);
      foundWorker.terminate();
      delete this.allWorkers[workerId];
      if (this.debugMode)
        console.log('Terminated worker, remaining workers', {
          ...this.allWorkers
        });
    }
  };

  ifcMarkUUID = value => {
    return `${value.jobNumber}${value.markNumber}`;
  };

  get allPanelRevitStoreEntities() {
    return this.rootStore.panelRevitStore.entities
      .slice()
      .filter(
        panelRevit =>
          panelRevit?.markNumber &&
          !panelRevit.markNumber.includes('SMP') &&
          !panelRevit.markNumber.includes('FBF') &&
          !panelRevit.markNumber.includes('MU')
      );
  }
  get allPanelRevitStoreMarks() {
    return this.allPanelRevitStoreEntities.slice().map(p => p.markUUID);
  }

  get uniquePanelMarkNumbers() {
    return uniqueArray(this.allPanelRevitStoreMarks);
  }

  get allIfcStoreEntities() {
    return this.rootStore.ifcStatusStore.entities
      .slice()
      .filter(
        ifcStatus =>
          ifcStatus?.markNumber &&
          !ifcStatus.markNumber.includes('SMP') &&
          !ifcStatus.markNumber.includes('FBF') &&
          !ifcStatus.markNumber.includes('MU') &&
          !ifcStatus.markNumber.includes('PM')
      );
  }
  get allIfcStoreMarkUUIDs() {
    return this.allIfcStoreEntities.slice().map(curr => {
      return this.ifcMarkUUID(curr);
    });
  }

  get uniqueIFC() {
    return uniqueArray(this.allIfcStoreMarkUUIDs);
  }

  get duplicateIFCs() {
    return getDupes(this.allIfcStoreMarkUUIDs);
  }

  get ifcMarkContainsSpaces() {
    return this.allIfcStoreMarkUUIDs.filter(markUUID => markUUID.includes(' '));
  }

  get dataGridDupes() {
    return this.allIfcStoreEntities.filter(ifcStatus => {
      return this.duplicateIFCs.includes(this.ifcMarkUUID(ifcStatus));
    });
  }

  get dataGridEntities() {
    const fromDupes = this.dataGridDupes.map(ifcStatus => {
      return {
        ...ifcStatus,
        type: 'Dupe',
        markUUID: this.ifcMarkUUID(ifcStatus),
        controlUUID: 'N/A'
      };
    });
    const fromIncludesSpaces = this.allIfcStoreEntities
      .filter(ifcStatus => {
        return this.ifcMarkContainsSpaces.includes(this.ifcMarkUUID(ifcStatus));
      })
      .map(ifcStatus => {
        return {
          ...ifcStatus,
          type: 'Spaces',
          markUUID: this.ifcMarkUUID(ifcStatus),
          controlUUID: 'N/A'
        };
      });

    const fromMissingPanels = this.allIfcStoreEntities
      .filter(ifcStatus => {
        return this.missingPanelRelation.includes(this.ifcMarkUUID(ifcStatus));
      })
      .map(ifcStatus => {
        return {
          ...ifcStatus,
          type: 'IFC Status With No Panel Relation',
          markUUID: this.ifcMarkUUID(ifcStatus),
          controlUUID: 'N/A'
        };
      });
    const fromMissingIFC = this.allPanelRevitStoreEntities
      .filter(panelRevit => {
        return this.missingIFCRelation.includes(panelRevit.markUUID);
      })
      .map(panelRevit => {
        return {
          ...panelRevit,
          type: 'Panel With No IFC Status Relation',
          markUUID: panelRevit.markUUID
        };
      });
    return [
      ...fromDupes,
      ...fromIncludesSpaces,
      ...fromMissingPanels,
      ...fromMissingIFC
    ];
  }

  get missingIFCRelation() {
    return this.uniquePanelMarkNumbers.slice().filter(panelMark => {
      const foundPanelPlant = !this.uniqueIFC
        .slice()
        .some(ifcMark => ifcMark === panelMark);
      return foundPanelPlant;
    });
  }

  get missingPanelRelation() {
    return this.uniqueIFC.slice().filter(panelMark => {
      const foundPanelPlant = !this.uniquePanelMarkNumbers
        .slice()
        .some(ifcMark => ifcMark === panelMark);
      return foundPanelPlant;
    });
  }

  get panelsWithMarkNotMatchingMarkUuid() {
    return this.rootStore.panelRevitStore.entities
      .slice()
      .filter(p => !p.markUUID.includes('SMP'))
      .filter(p => !p.markUUID.includes('MU'))
      .filter(p => p.markUUID !== p.jobNumber + p.markNumber)
      .map(panel => {
        let currentPanel = panel;
        const lookupPlantPanel = this.rootStore.panelPlantStore.entities.find(
          i => i.controlUUID === currentPanel.controlUUID
        );
        const lookupYardPanel = this.rootStore.panelYardStore.entities.find(
          i => i.controlUUID === currentPanel.controlUUID
        );
        const lookupFieldPanel = this.rootStore.panelFieldStore.entities.find(
          i => i.controlUUID === currentPanel.controlUUID
        );

        currentPanel.plantMarkMatches =
          (lookupPlantPanel &&
            lookupPlantPanel.markUUID === currentPanel.markUUID &&
            lookupPlantPanel.markNumber === currentPanel.markNumber) ||
          false;
        currentPanel.yardMarkMatches =
          (lookupYardPanel &&
            lookupYardPanel.markUUID === currentPanel.markUUID &&
            lookupYardPanel.markNumber === currentPanel.markNumber) ||
          false;
        currentPanel.fieldMarkMatches =
          (lookupFieldPanel &&
            lookupFieldPanel.markUUID === currentPanel.markUUID &&
            lookupFieldPanel.markNumber === currentPanel.markNumber) ||
          false;

        const markSliced = currentPanel.markNumber.slice(0, -1);
        if (currentPanel.markUUID === currentPanel.jobNumber + markSliced) {
          return {
            ...currentPanel,
            error: `Mark UUID Missing Letter Designation (${currentPanel.markNumber.slice(
              -1
            )})`
          };
        }
        const checkIfReturn = currentPanel.markNumber.includes('-');
        if (checkIfReturn) {
          const foundMarkUUIDDashIndex = currentPanel.markUUID.indexOf('-');
          const foundMarkNumberDashIndex = currentPanel.markNumber.indexOf('-');
          const checkMarkUUIDReturn = currentPanel.markUUID.slice(
            0,
            foundMarkUUIDDashIndex
          );
          const checkMarkUUIDParsed = checkMarkUUIDReturn.replace(
            currentPanel.jobNumber,
            ''
          );
          const checkMarkUUIDReturnLetter = checkMarkUUIDReturn.slice(-1);
          const checkMarkNumberReturn = currentPanel.markNumber.slice(
            0,
            foundMarkNumberDashIndex
          );
          const checkMarkNumberReturnLetter = checkMarkNumberReturn.slice(-1);

          if (
            checkMarkUUIDParsed + checkMarkNumberReturnLetter ===
            checkMarkNumberReturn
          ) {
            return {
              ...currentPanel,
              error: `Mark UUID Missing Letter Designation (${checkMarkNumberReturnLetter})`
            };
          }
          if (
            isNaN(parseInt(checkMarkNumberReturnLetter)) &&
            isNaN(parseInt(checkMarkUUIDReturnLetter)) &&
            checkMarkNumberReturnLetter !== checkMarkUUIDReturnLetter
          ) {
            return {
              ...currentPanel,
              error: `Mark UUID Needs Letter Updated To (${checkMarkNumberReturnLetter})`
            };
          }
        } else {
          const checkMarkBase = currentPanel.markNumber.slice(0, -1);
          const checkMarkUUIDBase = currentPanel.markUUID
            .slice(0, -1)
            .replace(currentPanel.jobNumber, '');
          const checkMarkUUIDLetter = currentPanel.markUUID.slice(-1);
          const checkMarkNumberLetter = currentPanel.markNumber.slice(-1);
          if (
            isNaN(parseInt(checkMarkNumberLetter)) &&
            isNaN(parseInt(checkMarkUUIDLetter)) &&
            checkMarkNumberLetter !== checkMarkUUIDLetter &&
            checkMarkBase === checkMarkUUIDBase
          ) {
            return {
              ...currentPanel,
              error: `Mark UUID Needs Letter Updated To (${checkMarkNumberLetter})`
            };
          }
        }
        const markFromUUID = currentPanel.markUUID.replace(
          `${currentPanel.jobNumber}`,
          ''
        );
        return {
          ...currentPanel,
          error: `Mark UUID Wrong ${markFromUUID} => (${currentPanel.markNumber})`
        };
      });
  }
}

export default DbAuditStore;
